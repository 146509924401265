import React, {Component} from 'react';
import { Link } from 'react-router-dom';
// import SubHelpPrompt from '../components/Common/HelpPrompt';
import withRouter from '../components/Functions/WithRouter';

const logoImgWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/siftaskforce/stf-logo-white.png';
const facebookIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/facebook-icon-white.png';
const twitterIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/twitter-icon-white.png';
const instagramIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/instagram-icon-white.png';
const youtubeIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/youtube-icon-white.png';

class Footer extends Component {
    constructor(props) {
      super(props)

      this.state = {
        hideFreeTools: true,
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount() {
      console.log('footer called');

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in footer ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData in footer')

      // let emailId = localStorage.getItem('email');
      // let cuFirstName = localStorage.getItem('firstName');
      // let cuLastName = localStorage.getItem('lastName');
      // let accountCode = localStorage.getItem('emp');
      // let roleName = localStorage.getItem('roleName');
      // let orgFocus = localStorage.getItem('orgFocus');
    }

    passCommand(command) {
      console.log('passCommand called', command)

      this.setState({ passedCommand: command })

    }

    render() {

      return (
          <div>
            <footer>
              <div className="bottom-padding-20 heading-text-4">
                {/*
                <div className={(this.state.hideFreeTools) ? "relative-column-33" : "relative-column-25"}>
                  <p className="bold-text white-text">Beneficiaries</p>
                  <div className="spacer" /><div className="half-spacer" />

                  <ul className="footer-nav">
                    <li><Link to={'/employers'}>Customers</Link></li>
                    <li><Link to={'/intermediaries'}>Workers</Link></li>
                  </ul>
                </div>*/}
                <div className="relative-column-50">
                  <p className="bold-text white-text">Company</p>
                  <div className="spacer" /><div className="half-spacer" />
                  <ul className="footer-nav">
                    <li><Link to={'/about'}>About</Link></li>
                    {/*<li><Link to={'/blog'}>Blog</Link></li>
                    <li><Link to={'/partners'}>Partners</Link></li>*/}
                    {/*<li><Link to={'/help'}>Help & Support</Link></li>*/}
                  </ul>
                </div>
                <div className="relative-column-50">
                  <p className="bold-text white-text">Getting Started</p>
                  <div className="spacer" /><div className="half-spacer" />
                  <ul className="footer-nav">

                    <li><button className="background-button white-text" onClick={() => this.props.navigate('/join')}>Join</button></li>
                    {/*
                      <li><a href="https://forms.gle/ud5CidsDnRBETiTt8" target="_blank" rel="noopener noreferrer">Join the Beta</a></li>
                    <li><Link to={'/join'}>Sign Up</Link></li>
                    <li><Link to={'/signin'}>Sign In</Link></li>
                    <li><Link to={'/marketplace'}>Marketplace</Link></li>
                    <li><Link to={'/pricing'}>Pricing</Link></li>
                    <li><Link to={'/contact'}>Contact</Link></li>*/}
                  </ul>
                </div>
                <div className="clear" />
              </div>
              <div className="clear" />

              <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />

              <hr />

              <div className="spacer" />
              {/*
              <div className="top-padding-20">
                <Link to={'/'}><img src={logoImgWhite} alt="Compass logo" className="logo-footer" /></Link>
                <ul className="social-links-2 center-text top-padding-15">
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/siftaskforce/"><img src={facebookIconWhite} alt="GC" className="image-23-fit" /></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/siftaskforce/"><img src={twitterIconWhite} alt="GC" className="image-23-fit" /></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/siftaskforce/"><img src={instagramIconWhite} alt="GC" className="image-23-fit" /></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCHO9ddn2kc_8hG8KFJZUH4w"><img src={youtubeIconWhite} alt="GC" className="image-23-fit" /></a></li>
                </ul>
                <div className="clear" />
              </div>*/}

              <div className="row-10">
                <p className="copyright-text white-text">Copyright {new Date().getFullYear()} by Sif Task Force. All rights reserved.</p>
              </div>

              <div className="spacer" />

            </footer>

            {/*<SubHelpPrompt orgCode={null} passedCommand={this.state.passedCommand} />*/}
          </div>
      )
    }
}

export default withRouter(Footer);
