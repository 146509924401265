import React, {Component} from 'react';

import TopNavigation from './TopNavigation';
import Footer from './Footer';

import withRouter from '../components/Functions/WithRouter';

class ContactPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
        reasonOptions: ['','Access to Employer Portal',"Integrate with Applicant Tracking System","Access to Mentor Portal","Guided Compass to Work with My Org / School","Post a Work Opportunity","Host a Talk / Workshop","I Forgot My Password","I Don't Know the Org Code of My Organization","Upgrade My Account","Schedule Demo of Employer Portal","I'd like to bulk upload my programs & courses","Buy Subscription Licenses","Other"],
      }
    }

    componentDidMount() {

      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "#F5F5F5";

      if (this.props.location && this.props.location.state) {

        const { reason, body, roleName } = this.props.location.state;

        if (reason !== '') {
          this.setState({ reason, body, roleName })
        }
      } else if (this.props.params) {
        let { reason, body } = this.props.params

        if (reason) {
          reason = reason.replace(/-/g," ")
        }
        if (body) {
          body = body.replace(/-/g," ")
        }

        this.setState({ reason, body })
      }
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = "#fff";
    }

    render() {
        return (
          <div>
            <TopNavigation currentPage={"contactGuidePage"} darkBackground={true} />

            <div className="contact-container">
              <br/>
              <h2>Contact Us</h2>


            </div>

            <Footer />

          </div>

        )
    }
}

export default withRouter(ContactPage);
