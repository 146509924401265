import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {signOut} from './Services/AuthRoutes';
import {globalVars} from '../config/globalVars';
import {bootUser} from './Functions/BootUser';
import withRouter from './Functions/WithRouter';

const logoImgWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/siftaskforce/stf-logo-white.png';
const homeIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/home-icon-white.png';
// const settingsIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/settings-icon-white.png';
const settingsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/settings-icon-dark.png';
const exitIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exit-icon-dark.png';
const messageIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/message-icon-white.png';
const notificationsWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/notification-icon.png';
const dropdownArrowWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow-white.png';
const profileIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-white.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const naviconIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/navicon-icon-white.png';

class AppNavigation extends Component {
    constructor(props) {
        super(props)

        this.state = {
          hideProfile: true,
          prefix: '/app',
          mobileNavClass: 'nav-items',
          isVisible: true,
          isMobileView: false,
          unreadNotificationsCount: 0,

          disableBootUser: true,
          hideSearch: true,
          hideMessages: true,
          hideNotifications: true,
          hidePrimaryOptions: true,

          applicationMaterials: [
            { name: 'Edit Profile Info', url: '/app/edit-profile', icon: profileIconDark },
          ],
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.countUnreadNotifications = this.countUnreadNotifications.bind(this)
        this.mobileNav = this.mobileNav.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.searchResultClicked = this.searchResultClicked.bind(this)

        this.renderModal = this.renderModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderDesktopNav = this.renderDesktopNav.bind(this)
        this.renderMobileNav = this.renderMobileNav.bind(this)

    }

    componentDidMount() {
      console.log('appNavigation called');

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');

      if (!cuFirstName) {
        cuFirstName = 'Anonymous'
      }

      if (!cuLastName) {
        cuLastName = 'User'
      }

      let isMobileView = false
      let mobileNavClass = 'mobile-app-nav-items'
      let isVisible = true

      if (window.innerWidth < 767) {
        isMobileView = true
        mobileNavClass = 'hide-mobile-app-items'
        isVisible = false
      }

      let prefix = '/app'

      let applicationMaterials = []
      applicationMaterials.unshift({ name: 'Edit Profile Info', url: prefix + '/edit-profile', icon: profileIconDark })

      this.setState({ emailId: email, cuFirstName, cuLastName,
        isMobileView, mobileNavClass, isVisible,
        applicationMaterials,
        prefix
      })

      this.retrieveData(null, null, email, null, null, null, null)
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in appNav ', this.props, prevProps)

    }

    retrieveData(orgCode, accountCode, emailId, employerAccount, roleName, remoteAuth, roleNameTitle) {
      console.log('retrieveData called within appNav')

      if ((!emailId || emailId === '') && !this.state.disableBootUser) {
        if (!this.props.skipRedirect) {
          let bootPath = '/signin'
          if (globalVars.useHashInProduction && window.location.hostname !== 'localhost') {
            bootPath = '/#/signin'
          }
          bootUser(this.props.navigate, null, bootPath)
        }
      } else {

       //  Axios.get('/api/users/profile/details/' + emailId, { params: { emailId } })
       //   .then((response) => {
       //     console.log('query for profile data worked');
       //     if (response.data.success) {
       //       const pictureURL = response.data.user.pictureURL
       //
       //       this.setState({ pictureURL })
       //
       //     } else {
       //       console.log('error response', response.data)
       //     }
       //
       // }).catch((error) => {
       //     console.log('query for profile info did not work', error);
       // })
      }
    }

    mobileNav (event) {
      console.log('mobile nav clicked', this.state.mobileNavClass, this.state.isVisible, this.state.isMobileView)
      if (this.state.mobileNavClass === "mobile-app-nav-items") {
        this.setState({
            mobileNavClass: 'hide-mobile-app-items',
            isVisible: false,
            isMobileView: true
        })
      } else {
        this.setState({
            mobileNavClass: 'mobile-app-nav-items',
            isVisible: true,
            isMobileView: true
        })
      }
    }

    countUnreadNotifications(notifications) {
      console.log('countUnreadNotifications called')
      let unreadNotificationsCount = 0;

      for (let i = 1; i <= notifications.length; i++) {
        console.log(i);

        if (notifications[i - 1].isRead === false) {
          unreadNotificationsCount = unreadNotificationsCount + 1
        }
      }

      console.log('show unreadNotificationsCount', unreadNotificationsCount)
      localStorage.setItem('unreadNotificationsCount', unreadNotificationsCount)
      this.setState({ unreadNotificationsCount })
    }

    handleChange(e) {
      console.log('handleChange');

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        self.setState({ searchResults: null })

        const searchString = self.state.searchString
        const emailId = self.state.emailId

        // Axios.get('/api/home/search', { params: { searchString, emailId } })
        // .then((response) => {
        //     console.log('Home search worked', response.data.success, response.data);
        //
        //     if (response.data.success) {
        //
        //       let searchResults = []
        //       if (response.data.members) {
        //         for (let i = 1; i <= response.data.members.length; i++) {
        //           let imageURL = profileIconDark
        //           if (response.data.members[i - 1].pictureURL) {
        //             imageURL = response.data.members[i - 1].pictureURL
        //           }
        //           searchResults.push({
        //             name: response.data.members[i - 1].firstName + ' ' + response.data.members[i - 1].lastName,
        //             email: response.data.members[i - 1].email,
        //             imageURL,
        //             url: self.state.prefix + '/profile/' + response.data.members[i - 1].username
        //           })
        //         }
        //       }
        //
        //       self.setState({ searchResults })
        //
        //     } else {
        //       console.log('there was an error:', response.data.message);
        //
        //     }
        // });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

      this.setState({
        searchString: e.target.value,
        showSearchResults: true
      })

    }

    handleKeyPress(e) {

      if (e.key === 'Enter') {
        console.log('enter was pressed')

        //not from suggestions
        // this.navigateToCareerPath(false, 0)

        //this.props.parentMethod();
        //this.props.searchTerm = this.state.searchTerm
        //this.props.navigate('./app/career-pathways/' + this.state.searchTerm)
      }
    }

    searchResultClicked(i) {
      console.log('searchResultClicked called: ')
    }

    renderSearchResults() {

      if (this.state.searchResults) {

        return (
          <div key="searchResults">
            {this.state.searchResults.map((item, index) =>
              <div key={index}>
                <span className="row-5">
                  <Link className="background-button left-text" to={item.url} onClick={() => this.setState({ showSearchResults: false, searchString: '' })}>
                    <div className="fixed-column-40">
                      <img src={(item.imageURL) ? item.imageURL : settingsIconDark} alt="GC" className="image-30-fit" />
                    </div>
                    <div className="calc-column-offset-40 top-padding-5">
                      <label>{item.name}</label><label className="description-text-2 description-text-color left-padding">({item.category})</label>
                    </div>
                    <div className="clear" />
                  </Link>
                </span>
              </div>
            )}
          </div>
        )
      } else {
        return
      }
    }

    renderModal() {
      // console.log('renderModal called')

      return (
        <div key="modal">
          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           >


            {(this.state.showWorkspaceInfo) && (
              <div className="full-width padding-30">
                <p className="heading-text-2">About Communities</p>

                <div className="spacer" />

                <div className="row-10">
                  <p><label className="cta-color bold-text">Communities</label> are dedicated portals or workspaces of educational, workforce, staffing, and other career development institutions. Your core profile transfers, but events, projects, work opportunities, pathways, and members will differ.</p>
                </div>

                <div className="row-10">
                  <p>Use communities to connect with others, gain advocates, gain clarity, and get referred for opportunities that are a strong fit for you.</p>
                </div>


                <div className="spacer"/>

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>

              </div>
            )}

          </Modal>
        </div>
      )
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showWorkspaceInfo: false })

    }

    changeSubNavSelected(subNavSelected, resetStateVar) {
      console.log('changeSubNavSelected called', subNavSelected, resetStateVar)

      this.setState({ [resetStateVar]: false})
      if (this.props.changeSubNavSelected) {
        this.props.changeSubNavSelected(subNavSelected)
      }
    }

    renderDesktopNav(orgLogo,adviseeTerm, employerLogo) {
      console.log('renderDesktopNav called')

      const textSizeClass = "description-text-3"

      return (
        <div key='renderDesktopNav'>
          <div className="app-nav-2">

              <Link to={this.state.prefix + '/dashboard'}><img src={logoImgWhite} alt="GC" className="logo-nav" /></Link>
              {(!this.state.hideSearch) && (
                <span className="display-inline max-width-15-percent min-width-150 left-margin">
                  <div className="float-left white-background left-padding left-margin top-margin-15 relative-position">
                    <div className="float-left top-margin-8">
                      <img src={searchIcon} alt="GC" className="image-auto-15"/>
                    </div>
                    <div className="calc-column-offset-40">
                      <input type="text" className="text-field description-text-2 clear-border full-width" placeholder="Search..." onKeyPress={this.handleKeyPress} value={this.state.searchString} onChange={this.handleChange}/>
                    </div>
                    <div className="clear" />

                    {(this.state.showSearchResults && this.state.searchString !== '') && (
                      <div className={(window.innerWidth < 767) ? "display-inline absolute-position white-background z-index-1 standard-border padding-10 top-margin full-width" : "display-inline absolute-position white-background z-index-1 standard-border padding-10 top-margin-25 width-550"}>
                        {this.renderSearchResults()}
                      </div>
                    )}
                  </div>
                </span>
              )}

              <ul className={"nav-items-short flex-container row-direction justify-end"}>
                  <li>
                    <Link to={this.state.prefix + '/dashboard'} className={"flex-container flex-center"}>
                      <img src={homeIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                    </Link>
                    <Link to={this.state.prefix + '/dashboard'}>
                      <p className={"full-width center-text " + textSizeClass}>Home</p>
                    </Link>
                    {((window.location.pathname === this.state.prefix || window.location.pathname === this.state.prefix + '/dashboard')) && <div className="full-width height-3 white-background float-left" />}
                    <div className="clear"/>
                  </li>

                  {(!this.state.hideMessages) && (
                    <li>
                      {(!this.state.unreadMessageCount || this.state.unreadMessageCount === 0 ) ? (
                        <span>
                          <Link to={this.state.prefix + '/messaging'} className={"flex-container flex-center"}>
                            <img src={messageIconWhite} alt="GC" className={"image-25-auto top-margin-8 bottom-padding-5 bottom-margin-negative-5"} />
                          </Link>
                          <Link to={this.state.prefix + '/messaging'}>
                            <p className={"full-width center-text " + textSizeClass}>Messages</p>
                          </Link>
                          {(window.location.pathname === this.state.prefix + '/messaging') && <div className="full-width height-3 white-background float-left" />}
                          <div className="clear" />
                        </span>
                      ) : (
                        <span className={""}>
                          <div className={"float-left"}>
                            <Link to={this.state.prefix + '/messaging'} className={"flex-container flex-center"}>
                              <img src={messageIconWhite} alt="GC" className={"image-auto-25 top-margin-5 bottom-margin-negative-5 float-left"} />
                            </Link>
                            <Link to={this.state.prefix + '/messaging'}>
                              <p className={"full-width center-text " + textSizeClass}>Messages</p>
                            </Link>
                          </div>
                          <span className={"noti-bubble description-text-4 float-left error-background-color top-margin-12 left-margin-negative-20"}>{this.state.unreadMessageCount}</span>
                          <div className="clear" />
                          {(window.location.pathname === this.state.prefix + '/messaging') && <div className="full-width height-3 white-background float-left" />}
                          <div className="clear" />
                        </span>
                      )}
                    </li>
                  )}

                  {(!this.state.hideNotifications) && (
                    <li>
                      {(this.state.unreadNotificationsCount === 0 ) ? (
                        <div>
                          <Link to={this.state.prefix + '/notifications'} className={"flex-container flex-center"}>
                            <img src={notificationsWhite} alt="GC" className={"image-28-fit top-margin-5 bottom-padding-4 bottom-margin-negative-7"} />
                          </Link>
                          <Link to={this.state.prefix + '/notifications'}>
                            <p className={"full-width center-text " + textSizeClass}>Notifications</p>
                          </Link>
                          {(window.location.pathname === this.state.prefix + '/notifications') && <div className="full-width height-3 white-background float-left" />}
                          <div className="clear"/>
                        </div>
                      ) : (
                        <div className={""}>
                          <div className={"float-left"}>
                            <Link to={this.state.prefix + '/notifications'} className={"flex-container flex-center"}>
                              <img src={notificationsWhite} alt="GC" className={"image-auto-25 top-margin-5 bottom-margin-negative-5 float-left"} />
                            </Link>
                            <Link to={this.state.prefix + '/notifications'}>
                              <p className={"full-width center-text " + textSizeClass}>Notifications</p>
                            </Link>
                          </div>

                          <span className={"noti-bubble description-text-4 float-left error-background-color top-margin-12 left-margin-negative-20"}>{this.state.unreadNotificationsCount}</span>

                          <div className="clear" />
                          {(window.location.pathname === this.state.prefix + '/notifications') && <div className="full-width height-3 white-background float-left" />}
                          <div className="clear"/>
                        </div>
                      )}
                    </li>
                  )}

                  <li>
                    <div className={"relative-position flex-container flex-center"}>
                      <button className={"background-button clear-margin clear-padding flex-container flex-center"} onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                        <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconWhite} alt="GC" className={"profile-thumbnail-30 white-outline top-margin bottom-margin-negative-5"}/>
                      </button>
                      <div className={"absolute-position right-0 bottom-0 bottom-margin-negative-10"}>
                        <button className="background-button clear-margin clear-padding top-margin" onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                          <img src={dropdownArrowWhite} alt="GC" className="image-auto-8"/>
                        </button>
                      </div>
                    </div>
                    <div className="clear" />
                    <button className="background-button clear-margin clear-padding description-text-1 float-left" onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                      <p className={"full-width center-text white-text top-margin-8 " + textSizeClass}>Profile</p>
                    </button>
                    <div className="clear"/>
                    {(window.location.pathname === this.state.prefix + '/edit-profile') && <div className="full-width height-3 white-background float-left" />}
                    <div className="clear"/>
                  </li>
                  <ReactTooltip id="tooltip-placeholder-id" />

              </ul>
              <a className="mobile-app-nav-icon" onClick={() => this.mobileNav()}><i className="ion-navicon-round"></i></a>
          </div>

          {(this.state.showProfileTabs) && (
            <div className={"profile-dropdown-menu-container-2"}>
              <div className="top-padding bottom-padding-5">

                {(!this.state.hideProfile) && (
                  <div>
                    <Link to={this.state.prefix + '/edit-profile'} className="background-button standard-color full-width">
                      <div>
                        <div className="fixed-column-40 right-padding">
                          <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 standard-border" />
                        </div>
                        <div className="calc-column-offset-40">
                          <p className="heading-text-5">{this.state.cuFirstName} {this.state.cuLastName}</p>
                          {/*<p className="description-text-3">{(this.state.headline && this.state.headline !== '') ? this.state.headline : ""}</p>*/}
                        </div>
                        <div className="clear" />
                      </div>
                    </Link>

                    <div className="spacer" />
                    <hr />
                  </div>
                )}

                <div className="row-5">
                  {(!this.state.hidePrimaryOptions) && (
                    <div>
                      <div className="spacer" />
                      {this.state.applicationMaterials.map((item, index) =>
                        <div key={index}>
                          <span className="full-width left-padding-5">
                            <Link className="background-button full-width right-padding-20 standard-color" onClick={() => this.setState({ showProfileTabs: false })} to={item.url}>
                              <div className="row-5">
                                <div className="float-left right-padding">
                                  <img src={item.icon} alt="GC" className="image-18-fit" />
                                </div>
                                <div className="float-left">
                                  <p className="description-text-2">{item.name}</p>
                                </div>
                                <div className="clear"/>
                              </div>
                            </Link>
                          </span>
                        </div>
                      )}

                      <div className="spacer" />

                      <hr />
                      <div className="spacer" />
                    </div>
                  )}

                  <div className="spacer" />

                  {(!this.state.hideProfile) && (
                    <div>
                      <span className="full-width left-padding-5">
                        <Link className="background-button full-width right-padding-20 standard-color" to={this.state.prefix + '/settings'}>
                          <div className="row-5">
                            <div className="float-left right-padding">
                              <img src={settingsIconDark} alt="GC" className="image-18-fit" />
                            </div>
                            <div className="float-left">
                              <p className="description-text-2">Settings</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </Link>
                      </span>
                    </div>
                  )}

                  {(!this.state.remoteAuth) && (
                    <div>
                      <span className="full-width left-padding-5">
                        <button className="background-button full-width right-padding-20 standard-color" onClick={() => signOut(
                          this.state.emailId, this.props.navigate, null
                        )}>
                          <div className="row-5">
                            <div className="float-left right-padding">
                              <img src={exitIconDark} alt="GC" className="image-18-fit" />
                            </div>
                            <div className="float-left">
                              <p className="description-text-2">Log Out</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </button>
                      </span>
                    </div>
                  )}
                </div>

                <div className="clear" />

              </div>
            </div>
          )}

          {this.renderModal()}
        </div>
      )
    }

    renderMobileNav(orgLogo,adviseeTerm) {
      console.log('renderMobileNav called')

      return (
        <div key="renderMobileNav">
          <div className="mobile-app-nav">
            <Link to={this.state.prefix + '/dashboard'}><img src={logoImgWhite} alt="GC" className="logo-nav" /></Link>

            {(!this.state.hideSearch) && (
              <div>
                <div className="calc-column-offset-200 white-background left-padding left-margin top-margin-15">
                  <div className="float-left top-margin-8">
                    <img src={searchIcon} alt="GC" className="image-auto-15"/>
                  </div>
                  <div className="calc-column-offset-40">
                    <input type="text" className="text-field description-text-2 clear-border full-width" placeholder="Search..." onKeyPress={this.handleKeyPress} value={this.state.searchString} onChange={this.handleChange}/>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.showSearchResults && this.state.searchString !== '') && (
                  <div className="search-results-container-mobile">
                    {this.renderSearchResults()}
                  </div>
                )}
              </div>
            )}

            <ul className={this.state.mobileNavClass}>
                <li><Link to={this.state.prefix + '/dashboard'}>Dashboard</Link></li>
                <li><Link to={this.state.prefix + '/edit-profile'}>Edit Profile</Link></li>
                {(!this.state.hideMessages) && (
                  <li><Link to={this.state.prefix + '/messaging'}>Messages</Link></li>
                )}
                {(!this.state.hideNotifications) && (
                  <li><Link to={this.state.prefix + '/notifications'}>Notifications</Link></li>
                )}

                <li><Link to={this.state.prefix + '/settings'}>Settings</Link></li>
            </ul>

            <a className="mobile-app-nav-icon" onClick={() => this.mobileNav()}>
              <img src={naviconIconWhite} alt="GC" className="image-23-fit top-margin-3" />
            </a>
          </div>
        </div>
      )
    }

    render() {

      if (this.state.isMobileView === false) {

        return (
            <nav className="sticky-nav">
              {this.renderDesktopNav(null,null)}
            </nav>
        )
      } else {

        return (

            <nav>
              {this.renderMobileNav(null,null)}
              {this.renderModal()}
            </nav>
        )
      }
    }
}

export default withRouter(AppNavigation);
