import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import withRouter from './Functions/WithRouter';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

class ChangePassword extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.savePassword = this.savePassword.bind(this)
      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }


    retrieveData() {
      console.log('retrieveData called in commonChangePassword')

      let emailId = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');

      this.setState({ emailId, roleName, username, cuFirstName, cuLastName })

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event)

      if (event.target.name === 'oldPassword') {
        this.setState({ oldPassword: event.target.value })
      } else if (event.target.name === 'newPassword') {
        this.setState({ newPassword: event.target.value })
      } else if (event.target.name === 'repeatedPassword') {

        if (this.state.newPassword !== '' && event.target.value === this.state.newPassword) {
          this.setState({ repeatedPassword: event.target.value, successMessage: 'New passwords match', errorMessage: '' })
        } else if (this.state.newPassword !== '') {
          this.setState({ repeatedPassword: event.target.value, successMessage: '', errorMessage: 'New passwords do not match' })
        } else {
          this.setState({ repeatedPassword: event.target.value })
        }
      }

    }

    savePassword() {
      console.log('called savePassword')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      const oldPassword = this.state.oldPassword
      const newPassword = this.state.newPassword
      const repeatedPassword = this.state.repeatedPassword
      const emailId = this.state.emailId

      if (oldPassword === '') {
        this.setState({ isSaving: false, errorMessage: 'Please enter your old password' })
      } else if (newPassword === '') {
        this.setState({ isSaving: false, errorMessage: 'Please enter your new password' })
      } else if (repeatedPassword === '') {
        this.setState({ isSaving: false, errorMessage: 'Please repeat your new password' })
      } else if (newPassword !== repeatedPassword) {
        this.setState({ isSaving: false, errorMessage: 'Your new passwords dont match. Please double check.' })
      } else if (!newPassword || newPassword.length < 7) {
        this.setState({ isSaving: false, errorMessage: 'please enter a password over 6 characters' })
      } else {

        Axios.post('/api/users/password/change', { emailId, oldPassword, newPassword })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Password changed successfully worked', response.data);

            let successMessage = 'Password changed successfully!'
            if (this.state.modalIsOpen) {
              successMessage = "Password changed successfully! You can change your password again from your settings page."
            }
            this.setState({ successMessage, isDone: true, isSaving: false })
            localStorage.removeItem('temporaryPassword')

          } else {
            console.log('request was not successful', response.data.message)
            this.setState({ errorMessage: response.data.message, isSaving: false })
          }

        }).catch((error) => {
            console.log('Password change did not work', error);
            this.setState({ errorMessage: error, isSaving: false })
        });
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} />

          <div className="standard-container">
            <div>
              <div className="calc-column-offset-30">
                <p className="heading-text-2">Change Password</p>
              </div>
              {(this.state.modalIsOpen) && (
                <div className="fixed-column-30">
                  <button className="background-button" onClick={() => this.closeModal()}>
                    <img className="image-auto-20" alt="GC" src={deniedIcon} />
                  </button>
                </div>
              )}
              <div className="clear" />
            </div>

            <div className="spacer" />

            {(this.state.isDone && this.state.modalIsOpen) ? (
              <div>
                <div className="row-30">
                  <p className="cta-color">{this.state.successMessage}</p>
                </div>

                <div className="spacer" />

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            ) : (
              <div>
                <div className="edit-profile-row">
                  <label className="profile-label">Current Password</label>
                  <input className="password" type="password" placeholder="Enter current password" name="oldPassword" value={this.state.oldPassword} onChange={this.formChangeHandler}/>
                </div>

                <div className="edit-profile-row">
                  <label className="profile-label">New Password</label>
                  <input className="password" type="Password" placeholder="Enter new Password" name="newPassword" value={this.state.newPassword} onChange={this.formChangeHandler}/>
                </div>

                <div className="edit-profile-row">
                  <label className="profile-label">Confirm New Password</label>
                  <input className="password" type="Password" placeholder="Repeat new password" name="repeatedPassword" value={this.state.repeatedPassword} onChange={this.formChangeHandler}/>
                </div>

                <div className="spacer" /><div className="spacer" />

                {(this.state.successMessage && this.state.successMessage !== '') && (
                  <p className="description-text-2 cta-color vertical-margin-10">{this.state.successMessage}</p>
                )}

                {(this.state.errorMessage && this.state.errorMessage !== '') && (
                  <p className="description-text-2 error-color vertical-margin-10">{this.state.errorMessage}</p>
                )}

                <button className="btn btn-primary" disabled={(this.state.isSaving) ? true : false} onClick={() => this.savePassword()}>Save New Password</button>
              </div>
            )}
          </div>
          {AppFooter(this.props.navigate,null, null)}
        </div>
      )
    }
}

export default withRouter(ChangePassword)
