import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import Footer from './Footer';

const purposeDiagram = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/purpose-diagram.png";
const creightonTaylor = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/creighton_taylor.png';
const paulDawson = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paul_dawson.png";
const sebastianDeVivo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/sebastian_de_vivo.png";
const staceyGordon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/stacey_gordon.png";
const anitaDeFrantz = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/anita_defrantz.jpg";
const larryTaylor = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/larry_taylor.jpg";
const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";

class AboutPage extends Component {
    constructor(props) {
      super(props)

      this.state = {

        leaders: [],
        posts: [],
        filteredPosts: [],

        selectedIndex: 0

      }
    }

    componentDidMount() {

      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "white";

      const leaders = [
        { pic: creightonTaylor, name: 'Creighton Taylor, CFA', title: 'Chief Executive Officer', headline: 'Harvard MBA Grad, Emory BBA, CFA Holder', background: 'Product manager for various startups, prior investment professional, launched 10+ apps, founder of four workforce development organizations.'},
        { pic: paulDawson, name: 'Paul Dawson', title: 'Chief Technology Officer', headline: 'MIT Engineering Grad, Full-Stack Developer', background: 'Worked as software engineer for large companies and startups, including Cox Automotive and RentPath.'},
        { pic: sebastianDeVivo, name: 'Sebastian De Vivo, PhD', title: 'Product Advisor', headline: 'Stanford PhD, Future of Work Specialist', background: 'Procured $30MM in Future of Work grants with DOL and local agencies, Advisor at Bixel Exchange and StartOut.'},
        { pic: staceyGordon, name: 'Stacey Gordon', title: 'HR & Recruiting Advisor', headline: 'Pepperdine MBA, Forbes Coaching Council', background: 'Recruiting courses have received 1MM+ views, named 40 under 40, UCLA Coding Bootcamp advisor.'},
        { pic: anitaDeFrantz, name: 'Anita DeFrantz', title: 'Legal Advisor', headline: 'UPenn Law Grad, IOC Member', background: 'Vice President of International Olympic Committee, Former CEO of LA84 Foundation, Two-Time Olympic Medal Winner. Named one of the "The 100 Most Powerful Women in the World.'},
        { pic: larryTaylor, name: 'Larry Taylor, PhD', title: 'Strategy Advisor', headline: 'Claremont PhD, MBA from Pepperdine, Corporate Director', background: 'Former productivity consulting practice leader for EY & Deloitte. Former CFO of OBN Holdings (OBNI:OTC). Former professor of California State University, LA, CEO of National Association of Corporate Directors - Pacific Southwest.'}
      ]

      this.setState({ leaders })

      Axios.get('/api/blog-posts')
      .then((response) => {
        console.log('Blog post query attempted', response.data);

        if (response.data.success) {
          console.log('blog post query worked')

          if (response.data.posts.length !== 0) {
            let posts = response.data.posts
            // for (let i = 1; i <= response.data.posts.length; i++) {
            //   if (response.data.posts[i - 1].slug === 'how-we-complement-portfolium' || response.data.posts[i - 1].slug === 'why-found-guided-compass?' || response.data.posts[i - 1].slug === 'what-are-guided-compass-assessments?') {
            //     posts.push(response.data.posts[i - 1])
            //     console.log('testing 1', posts[i - 1])
            //   }
            // }

            const filteredPosts = posts
            console.log('show filteredPosts: ', filteredPosts)
            this.setState({ posts, filteredPosts});
          }

        } else {
          console.log('Blog posts query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Blog posts query did not work for some reason', error);
      });

    }

    render() {
        return (

          <div>
              <TopNavigation currentPage={"aboutPage"} darkBackground={true} />

              <section className="section-features half-bold-text">
                <div className="row center-text">
                  <br/>
                  <h1>About Sif Task Force</h1>

                  <div className="top-margin-50">
                    <p className="heading-text-5">The Sif Task Force application is a sophisticated project management and collaboration tool designed to integrate artificial intelligence (AI) and human expertise. It is a subset of AI Sif Tech Landscape created by <a href="https://sifchain.network/" target="_blank" rel="noopener noreferrer">Sifchain</a>.</p>
                  </div>

                  <div className="top-margin-50">
                    <p className="heading-text-5">To learn more about Sif and Sifchain, please read the following document <a href="https://docs.google.com/document/d/16MAPc6WnUbIwXG3qH_ugoJMYXVmydW4wIT-WNevzfuI/edit" target="_blank" rel="noopener noreferrer">here</a>.</p>
                  </div>
                </div>
              </section>

              <Footer />
          </div>

        )
    }
}

export default AboutPage;
