import React, {Component} from 'react';
import Axios from 'axios';
import withRouter from './Functions/WithRouter';
import {globalVars} from '../config/globalVars';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class GitHubCallback extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.navigateToDashboard = this.navigateToDashboard.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { user_token } = this.props.params

      if (user_token) {

        let baseURL = 'https://api.siftaskforce.com'
        if (globalVars.useTestAPI) {
          baseURL = 'https://testapi.siftaskforce.com'
        }

        // retrieve profile data
        Axios.get(baseURL + '/profile', { params: { access_token: user_token }})
        .then((response) => {
          console.log('Profile query attempted', response.data);

          if (response.data.success) {
            console.log('profile info query worked')

            localStorage.setItem('username', response.data.profile.name)
            // if (response.data.profile && response.data.profile.user_token) {
            //   localStorage.setItem('github_user_token', response.data.profile.access_token)
            // }

            if (response.data.profile && response.data.profile.email) {
              localStorage.setItem('email', response.data.email)
              this.navigateToDashboard()
            } else {
              this.setState({ collectEmail: true, id: response.data.profile.id, user_token })
            }

          } else {
            console.log('profile query did not work', response.data.message)
            //this.setState({ retrievedEmail: true })
          }
        }).catch((error) => {
            console.log('Profile query did not work for some reason', error);

        });
      }
    }

    navigateToDashboard() {
      console.log('navigateToDashboard called!')

      this.props.navigate('/app/dashboard')

      // if (globalVars.useHashInProduction && window.location.hostname !== 'localhost') {
      //   this.props.navigate('/app/dashboard')
      // } else {
      //   this.props.navigate('/app/dashboard')
      // }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event)

      this.setState({ [event.target.name]: event.target.value })

    }

    handleSubmit(event) {
      console.log('handleSubmit called in GitHubCallback')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.email) {
        this.setState({ errorMessage: 'Please add your email', isSaving: false })
      } else if (!this.state.email.includes('@')) {
        this.setState({ errorMessage: 'Please add a valid email', isSaving: false })
      } else {

        const user_token = this.state.user_token
        const email = this.state.email
        const userObject = { access_token: user_token, email }

        let baseURL = 'https://api.siftaskforce.com'
        if (globalVars.useTestAPI) {
          baseURL = 'https://testapi.siftaskforce.com'
        }

        Axios.put(baseURL + '/profile/email', userObject)
        .then((response) => {
          console.log('attempting to save email', response.data)
          if (response.data.success) {
            console.log('successfully saved profile')

            localStorage.setItem('email', email)
            this.navigateToDashboard()

          } else {
            console.log('profile save was not successful')

            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Saving the info did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    render() {

      return (
        <div>
          {(this.state.collectEmail) ? (
            <div>
              <div className="standard-container">
                <p className="heading-text-2">Please Add Your Email</p>
                <p className="row-10">We will use your email to notify you of relevant Sif Task Force information (e.g., when your tickets have been completed).</p>

                <div className="row-20 top-margin">
                  <label className="profile-label">Email</label>
                  <input className="text-field" type="text" placeholder="youremail@gmail.com" name="email" value={this.state.email} onChange={this.formChangeHandler} />
                </div>

                {(this.state.errorMessage) && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                <div className="row-20 top-margin">
                  <button onClick={this.handleSubmit} type="button" className="btn btn-primary">Save & Get Started</button>
                </div>

              </div>
            </div>
          ) : (
            <div className="flex-container flex-center full-space">
              <div>
                <div className="super-spacer" />

                <img src={loadingGIF} alt="STF" className="image-auto-80 center-horizontally"/>
                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                <p className="center-text cta-color bold-text">Loading...</p>

                {(this.state.errorMessage) && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

              </div>
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(GitHubCallback)
