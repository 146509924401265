import React, {Component} from 'react';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import Footer from './Footer';

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          email: '',
          successMessage: '',
          errorMessage: ''

        }

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
      // localStorage.setItem('userCredits', 100)
      window.scrollTo(0, 0)

      document.body.style.backgroundColor = "#F5F5F5";

      const roleOptions = ['','Student','Teacher','Mentor (Not Hiring)','Employer']

      this.setState({ roleOptions })

    }

    mobileNav (event) {
        console.log('mobile nav clicked')
        if (this.state.mobileNavClass === "side-nav") {
            this.setState({
                mobileNavClass: 'side-nav-mobile',
                isVisible: false
            })
        } else {
            this.setState({
                mobileNavClass: 'side-nav',
                isVisible: true
            })
        }
    }

    formChangeHandler = event => {
      this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit(event) {
      console.log('handleSubmit called')

      this.setState({ isSaving: true, successMessage: '', errorMessage: '' })

      if (this.state.email === '') {
        this.setState({ isSaving: false, errorMessage: 'Please enter your email'})
      } else if (!this.state.email.includes('@')) {
        this.setState({ isSaving: false, errorMessage: 'Please enter a valid email'})
      } else {
        //create temporary password and send
        const email  = this.state.email
        Axios.post('/api/users/password/reset', {
          email
        })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Password successfully reset', response.data);

            this.setState({ isSaving: false, successMessage: 'Password successfully reset!' })

          } else {
            console.log('password reset was not successful', response.data.message)
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }

        }).catch((error) => {
            console.log('Password reset did not work', error);
            this.setState({ isSaving: false, errorMessage: error })
        });

      }
    }

    render() {
        return (

          <div>
              <TopNavigation currentPage={"ResetPasswordPage"} darkBackground={true}/>

              <div className="contact-container">
                  <br/>
                  <h2>Reset Password</h2>

                  <label className="profile-label">After you enter your email address associated with your account, we will email you a temporary password. Login and change your password to something more easily remembered under settings -> change password.</label>

                  <div className="edit-profile-row">
                      <label className="profile-label">Email Address</label>
                      <input type="text" className="text-field" name="email" placeholder="Enter your email address" value={this.state.email} onChange={this.formChangeHandler}/>
                  </div>

                  <br/>
                  <button onClick={this.handleSubmit} disabled={(this.state.isSaving) ? true : false} className="btn btn-primary">Send Temporary Password</button>
                  { (this.state.successMessage !== '') && (
                    <p className="success-message">{this.state.successMessage}</p>
                  )}
                  { (this.state.errorMessage !== '') && (
                    <p className="error-message">{this.state.errorMessage}</p>
                  )}
              </div>

              <Footer />
          </div>

        )
    }
}

export default ResetPasswordPage;
