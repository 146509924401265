import React, { Component} from 'react';
import Axios from 'axios';
import SubLogInWidget from '../../components/Common/LogInWidget';
import withRouter from '../Functions/WithRouter';

class LoginForm extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)

    }

    componentDidMount() {
      console.log('login form page is mounting')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within login form ', this.props, prevProps)
    }

    retrieveData() {
      console.log('retrieveData in loginform')
    }

    render() {

      return (

        <div>
          <SubLogInWidget type={this.props.type} />
        </div>

      )
    }
}

export default withRouter(LoginForm);
