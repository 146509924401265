import React, { Component} from 'react';
import LoginForm from './Subcomponents/LoginForm';
import withRouter from '../components/Functions/WithRouter';

class SignUp extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      console.log('sign up page is mounting')
    }

    render() {

      return (
        <div>
          <LoginForm type="SignUp" />
        </div>
      )
    }
}

export default withRouter(SignUp);
