import React, {Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubInviteMembers from './Common/InviteMembers';
import {signOut} from './Services/AuthRoutes';
import withRouter from '../components/Functions/WithRouter';

class Settings extends Component {
    constructor(props) {
      super(props)
      this.state = {
        hideManageNotifications: true,
        demoMode: false
      }

      this.deleteAccount = this.deleteAccount.bind(this)
      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');

      this.setState({ emailId: email, username });

    }

    deleteAccount() {
      console.log('deleteAccount')

      Axios.delete('/api/users/' + this.state.emailId)
      .then((response) => {
        console.log('tried to  delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Post delete worked');

          signOut(this.state.emailId, this.props.navigate, null)

        } else {
          console.error('there was an error deleting the posting');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ errorMessage: error.toString() })
      });
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showInviteMembersWidget: false });
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} />

            <div>
              <div className="standard-container">

                  <p className="heading-text-2">Settings</p>

                  <div className="spacer" /><div className="spacer" />


                  {(!this.state.hideManageNotifications) && (
                    <div>
                      <hr/>

                      <div className="row-20">
                        <Link to={'/app/notification-preferences'}><p>Manage Notifications</p></Link>
                      </div>
                    </div>
                  )}

                  <hr />

                  {(this.state.demoMode) ? (
                    <div>
                      <div className="row-20">
                        <Link to={'/app/change-password'}><p>Change Password</p></Link>
                      </div>

                      <hr />

                      <div className="row-20">
                        <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showInviteMembersWidget: true })}>
                          <div>
                            <p className="cta-color">Invite People to Join</p>
                          </div>
                        </button>
                      </div>

                      <hr />

                      <div className="row-20">
                        <div className="full-width">
                          <button className="background-button left-text full-width" onClick={() => this.setState({ showConfirmDelete: true })}>
                            <div>
                              <p className="error-color">Delete Account Permanently</p>
                            </div>
                          </button>
                        </div>

                        {(this.state.showConfirmDelete) && (
                          <div className="full-width">
                            <label className="profile-label">Are you sure you want to delete your entire profile?</label>
                            <button className="btn btn-squarish error-background-color" onClick={() => this.deleteAccount()}>
                              Delete Permanently
                            </button>
                            <button className="btn btn-squarish white-background cta-color left-margin" onClick={() => this.setState({ showConfirmDelete: false })}>
                              Cancel
                            </button>

                            {(this.state.errorMessage) && <p className="row-10 description-text-2 error-color">{this.state.errorMessage}</p>}

                          </div>
                        )}

                        <div className="clear" />

                        <div className="spacer"/><div className="half-spacer"/>
                        <hr className="clear-margin clear-padding"/>
                        <div className="spacer"/><div className="half-spacer"/>
                      </div>

                      <div className="spacer"/>
                    </div>
                  ) : (
                    <div>
                      <div className="row-20">
                        <div className="full-width">
                          <button className="background-button left-text full-width" onClick={() => signOut(this.state.emailId, this.props.navigate, null)}>
                            <p className="error-color">Log Out</p>
                          </button>
                        </div>

                        <div className="clear" />

                        <div className="spacer"/><div className="spacer"/>
                        <hr className="clear-margin clear-padding"/>
                        <div className="spacer"/><div className="half-spacer"/>
                      </div>

                      <div className="spacer"/>
                    </div>
                  )}
              </div>
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter()}
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             >
              {(this.state.showInviteMembersWidget) && (
                <div key="export" className="full-width padding-20">
                  <SubInviteMembers closeModal={this.closeModal} />
                </div>
              )}

            </Modal>
          </div>
      )
    }
}

export default withRouter(Settings);
