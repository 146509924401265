import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Switch from 'react-switch';
import Modal from 'react-modal';
import SubBuyCredits from './Common/BuyCredits';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import {globalVars} from '../config/globalVars';
import withRouter from '../components/Functions/WithRouter';

const checkmarkIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const sifComicImage = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/siftaskforce/sif-comic-image.png"
const repoNameRetrievalSS = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/siftaskforce/repo-name-retrieval-ss.png"
const issueNumberRetrievalSS = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/siftaskforce/issue-number-retrieval-ss.png"
const callWaitingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/call-waiting-gif.gif';
const robotGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/siftaskforce/robot_gif.gif';
const checkmarkChecked = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-checked.png"
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';

class Dashboard extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isWhitelisted: true,

        hidePrepareYourData: true,
        disableTaskforce: false,
        testMode: false,
        hideGitHubAccessToken: false,

        showIssueToggle: true,
        createNewIssue: true,

        hideWhitelist: true,
        newConfirmationScreen: true,
        taskforceIsRunning: false,
        showPreviousTickets: false,
        showExampleTicket: false,
        showGoogleFormForCredits: true,
        use2Workers: true,

        userCredits: 100,

        subNavSelected: "Create a Ticket",
        subNavCategories: ['Create a Ticket','View Previous Tickets'],
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.startTaskforce = this.startTaskforce.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.getSubmittedTickets = this.getSubmittedTickets.bind(this)
      this.openAddCreditsWindow = this.openAddCreditsWindow.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let emailId = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let gitHubUserToken = localStorage.getItem('github_user_token');
      // let gitHubAccessToken = localStorage.getItem('github_access_token'); not encrypted
      let gitHubAccessToken = null
      let userCredits = localStorage.getItem('userCredits');
      if (!userCredits) {
        userCredits = 100
      }

      // localStorage.setItem('userCredits', 100)

      let variables = [
        { name: 'Repo Name', description: 'This is the name repo you’d like to use '},
        { name: 'Issue', description: 'This is the project that you want completed within the repo.'},
      ]

      if (!this.state.hideGitHubAccessToken) {
        variables.unshift({ name: 'GitHub Token', description: 'This is the classic token generated from your GitHub account'})
      }

      let repoBranch = undefined
      let repoName = undefined
      let issueNumber = undefined

      if ((!gitHubAccessToken && window.location.hostname === 'localhost') || this.state.testMode) {
        gitHubAccessToken = process.env.REACT_APP_GITHUB_ACCESS_TOKEN;
        if (this.state.testMode) {
          // repoBranch = this.state.repoBranch
          repoName ='creightontaylor/simple_website'
          issueNumber = 19
        }
      }

      let preloadedAccessToken = false
      if (gitHubAccessToken) {
        // preloadedAccessToken = true
      }
      console.log('show gitHubToken 1: ', gitHubUserToken, gitHubAccessToken, process.env.GITHUB_ACCESS_TOKEN)

      const tokenScopes = [
        { name: 'repo', access: 'read/write' },
        // { name: 'discussion', access: 'read/write' },
        // { name: 'notifications', access: null },
      ]
      this.setState({ emailId, roleName, username, cuFirstName, cuLastName, variables,
        gitHubUserToken, gitHubAccessToken, preloadedAccessToken, repoBranch, repoName, issueNumber, userCredits,
        tokenScopes
      })

      if (this.state.showPreviousTickets) {
        this.getSubmittedTickets()
      }
    }

    getSubmittedTickets() {
      console.log('getSubmittedTickets called')

      let baseURL = 'https://api.siftaskforce.com'
      if (globalVars.useTestAPI) {
        baseURL = 'https://testapi.siftaskforce.com'
      }

      const queryParams = { username: this.state.username }

      // retrieve profile data
      Axios.get(baseURL + '/runs', { params: queryParams })
      .then((response) => {
        console.log('Runs query attempted', response.data);

        if (response.data.success) {
          console.log('runs query worked')

          this.setState({ runs: response.data.runs })

        } else {
          console.log('runs did not work', response.data.message)
          //this.setState({ retrievedEmail: true })
        }
      }).catch((error) => {
          console.log('Runs did not work for some reason', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event)

      let isReady = false

      let gitHubAccessToken = this.state.gitHubAccessToken
      let repoName = this.state.repoName
      let issueNumber = this.state.issueNumber
      let credits = this.state.credits
      let deadline = this.state.deadline
      if (event.target.name === 'gitHubAccessToken') {
        gitHubAccessToken = event.target.value
      } else if (event.target.name === 'repoName') {
        repoName = event.target.value
      } else if (event.target.name === 'issueNumber') {
        issueNumber = event.target.value
      } else if (event.target.name === 'credits') {
        credits = event.target.value
      } else if (event.target.name === 'deadline') {
        deadline = event.target.value
      }

      if (this.state.createNewIssue) {
        if (this.state.name && this.state.description) {
          issueNumber = true
        }
      }

      if (gitHubAccessToken && repoName && issueNumber && credits && deadline) {
        isReady = true
      }

      this.setState({ [event.target.name]: event.target.value, isReady })

    }

    startTaskforce() {
      console.log('startTaskforce called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.gitHubAccessToken) {
        this.setState({ isSaving: false, errorMessage: "There was an issue retrieving your GitHub token" })
      } else if (!this.state.repoName) {
        this.setState({ isSaving: false, errorMessage: "Please add a repo" })
      } else if ((!this.state.createNewIssue && !this.state.issueNumber) || (this.state.createNewIssue && (!this.state.name || !this.state.description))) {
        this.setState({ isSaving: false, errorMessage: "Please add an issue" })
      } else if (!this.state.credits) {
        this.setState({ isSaving: false, errorMessage: "Please add the number of credits you want to spend" })
      } else if (!this.state.deadline) {
        this.setState({ isSaving: false, errorMessage: "Please add a deadline for this ticket" })
      } else {

        const github_token = this.state.gitHubAccessToken
        const repo_full_name = this.state.repoName
        let repo_issue_id = this.state.issueNumber

        const name = this.state.name
        const description = this.state.description
        const background = this.state.background
        const userStories = this.state.userStories
        const acceptanceCriteria = this.state.acceptanceCriteria
        const bugBehavior = this.state.bugBehavior
        const potentialSolutions = this.state.potentialSolutions
        const additionalNotes = this.state.additionalNotes

        const use2Workers = this.state.use2Workers

        let baseURL = 'https://api.siftaskforce.com'
        // if (globalVars.useTestAPI) {
        //   baseURL = 'https://testapi.siftaskforce.com'
        // }

        const header1 = "accept: application/json"
        const header2 = "Content-Type: application/json"
        let config = {
          headers: {
            header1,
            header2
          }
        }

        // const issue = {
        //   name, description, background, userStories, acceptanceCriteria, bugBehavior,
        //   potentialSolutions, additionalNotes
        // }

        const userCredits = this.state.userCredits - this.state.credits

        if (this.state.disableTaskforce) {
          localStorage.setItem('userCredits', userCredits)
          let gitHubLink = "https://github.com/" + repo_full_name + "/issues/" + repo_issue_id
          this.setState({ taskforceIsRunning: !this.state.taskforceIsRunning, gitHubLink, userCredits })
        } else {
          // console.log('postURL data: ', postURL, JSON.stringify(payload), JSON.stringify(config))

          const createNewIssue = this.state.createNewIssue

          const self = this

          function actuallyStartSTF(passedIssueNumber) {
            if (createNewIssue) {
              repo_issue_id = passedIssueNumber
            }

            let gitHubLink = "https://github.com/" + repo_full_name + "/issues/" + repo_issue_id

            console.log('what is happening: ', passedIssueNumber, repo_issue_id)

            const postURL = baseURL + "/system"

            const customerConfigs = {
              "name": "customer_agent",
              "reevaluation_duration": 60,
              "reevaluation_message": 2, // maximum number of messages
              "discussion_phase_time": 120, // number of seconds in the discussion phase
              "loop_wait": 10
            }

            const workersConfigs = [
              {
                "name": "Architect",
                "prompt": "You are senior software architect.",
                "minimum_rating": 8,
                "loop_wait": 10
              }]
            if (use2Workers) {
              workersConfigs.push(
                {
                  "name": "Software engineer",
                  "prompt": "You are junior software engineer.",
                  "minimum_rating": 8,
                  "loop_wait": 10
                }
              )
            }

            const payload = {
              repo_full_name, repo_issue_id, github_token,
              customer: customerConfigs, workers: workersConfigs
            }

            Axios.post(postURL, payload, config)
            .then((response) => {
              console.log('attempting to post to system')
              if (response.data.success || response.data.message === 'Taskforce system started') {
                console.log('posted successfully', response.data)

                localStorage.setItem('userCredits', userCredits)

                // if (createNewIssue && response.data.issueId) {
                //
                //   gitHubLink = "https://github.com/" + repo_full_name + "/issues/" + response.data.issueId
                // }

                self.setState({ isSaving: false, taskforceIsRunning: true, gitHubLink, userCredits })

              } else {
                console.log('did not post successfully', response.data)

                self.setState({ isSaving: false, errorMessage: response.data.message })
              }
            }).catch((error) => {
                console.log('save did not work', error);

                self.setState({ isSaving: false, errorMessage: error.toString() })

            });
          }

          if (createNewIssue) {
            const createIssueURL = baseURL + "/create_issue"

            const title = this.state.name
            let body = this.state.description

            if (this.state.background) {
              body = body + "``` Here is some background on this issue: " + this.state.background + "```"
            }

            if (this.state.userStories) {
              body = body + "``` Here are user stories on this c: " + this.state.userStories + "```"
            }

            if (this.state.acceptanceCriteria) {
              body = body + "``` Here are my acceptance criteria: " + this.state.acceptanceCriteria + "```"
            }

            if (this.state.bugBehavior) {
              body = body + "``` Here is the expected behavior vs actual behavior: " + this.state.bugBehavior + "```"
            }

            if (this.state.potentialSolutions) {
              body = body + "``` Here is the expected behavior vs actual behavior: " + this.state.potentialSolutions + "```"
            }

            if (this.state.additionalNotes) {
              body = body + "``` Here are additional notes: " + this.state.additionalNotes + "```"
            }

            const access_token = github_token

            const createIssuePayload = { repo_full_name, title, body, access_token}
            console.log('show payload: ', JSON.stringify(createIssuePayload))
            Axios.post(createIssueURL, createIssuePayload, config)
            .then((response) => {
              console.log('attempting to create issue')
              if (response.data.success) {
                console.log('posted successfully', response.data)
                actuallyStartSTF(response.data.issue_number)
              } else {
                console.log('did not create issue successfully', response.data)

                actuallyStartSTF()
              }
            }).catch((error) => {
                console.log('save did not work', error);

                actuallyStartSTF()
            });
          } else {

          }
        }
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showBuyCredits: false });
    }

    openAddCreditsWindow() {
      console.log('openAddCreditsWindow called')

      if (this.state.showGoogleFormForCredits) {
        window.open('https://forms.gle/mrEhoxtJWxWoEkcX7')
      } else {
        this.setState({ modalIsOpen: true, showBuyCredits: true })
      }
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} workMode={this.state.workMode} />

              {(this.state.taskforceIsRunning) ? (
                <div>

                  {(this.state.showPreviousTickets) && (
                    <div>
                      <div className="full-width white-background">
                        <div className="center-horizontally clear-float width-70-percent">
                          <div className="carousel-3" onScroll={this.handleScroll}>
                            {this.state.subNavCategories.map((value, index) =>
                              <div className="carousel-item-container">
                                {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                  <div className="selected-carousel-item">
                                    <label key={value}>{value}</label>
                                  </div>
                                ) : (
                                  <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                                    <label key={value}>{value}</label>
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <hr />
                    </div>
                  )}
                  <div className="standard-container">

                    {(this.state.subNavSelected === 'Create a Ticket') ? (
                      <div>
                        <div className="flex-container flex-center padding-50">
                          <div>
                            <img src={checkmarkChecked} alt="GC" className="image-100-fit center-horizontally" />
                            <p className="heading-text-2 bold-text full-width center-text top-margin-40">We're Completing Your Request</p>
                            <p className="full-width center-text top-margin-40">A.I. agents and human workers are working on your behalf to complete your requests on GitHub here:</p>

                            <p className="full-width center-text top-margin-20 row-20 heading-text-3 cta-color"><a href={"https://github.com/" + this.state.repoName + "/issues/" + this.state.issueNumber} target="_blank" rel="noopener noreferrer">{"https://github.com/" + this.state.repoName + "/issues/" + this.state.issueNumber}</a></p>

                            {/*<p className="full-width center-text top-margin-30 row-30 heading-text-3 cta-color">4:00pm PST</p>*/}
                            <p className="full-width center-text top-margin-30">You will receive an email notification when your ticket has been completed. You can then review the submitted pull request.</p>

                            {/*<p className="full-width center-text top-margin-30">Once this ticket has been resolved, you will be able to submit a new ticket.</p>*/}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="heading-text-1-and-a-half">Previous Tickets</p>
                        <p className="heading-text-5 top-margin-20">You have submitted the following tickets</p>

                        {(this.state.showExampleTicket) ? (
                          <div>
                            <div className="top-margin-40 card">
                              <div className="fixed-column-40">
                                <p className="heading-text-5">1.</p>
                              </div>
                              <div className="calc-column-offset-70 right-padding">
                                <p className="heading-text-5 bold-text">My Example Ticket Name</p>
                                <p className="top-margin-15 description-text-1">Status: <label className="bold-text cta-color">Completed</label></p>
                                <p className="top-margin-15 description-text-1">Total Time Spent: <label className="bold-text cta-color">3 hours</label></p>
                                <p className="top-margin-15 description-text-1">Estimated Time Savings: <label className="bold-text cta-color">2 hours</label></p>
                                <p className="top-margin-15 description-text-1">New Lines of Code Submitted: <label className="bold-text cta-color">400</label></p>
                                <p className="top-margin-15 description-text-1">Edit Lines of Code Submitted: <label className="bold-text cta-color">200</label></p>
                                <p className="top-margin-15 description-text-1 curtail-text">Commit Message: My example curtailed description that is long and detailed. It specifies many things about the project and is great because it shows that we really are out here doing things.</p>
                                <p className="top-margin-15 description-text-1">Link to PR: <a href="https://github.com/creightontaylor/simple_website/pull/14" target="_blank" rel="noopener noreferrer">https://github.com/creightontaylor/simple_website/pull/14</a></p>
                              </div>
                              <div className="fixed-column-30">
                                <img src={arrowIndicatorIcon} alt="STF" className="image-auto-22" />
                              </div>
                              <div className="clear" />

                            </div>

                            <div className="row-30">
                              <hr />
                            </div>
                          </div>
                        ) : (
                          <div>
                            {(this.state.submittedTickets) ? (
                              <div>
                              </div>
                            ) : (
                              <div className="row-30 top-margin-20">
                                <p className="description-text-color">You have not submitted any tickets.</p>
                              </div>
                            )}
                          </div>
                        )}

                      </div>
                    )}

                  </div>
                </div>
              ) : (
                <div>
                  <div className="standard-container">
                    <div>
                      <div className="calc-column-offset-190">
                        <p className="heading-text-1-and-a-half">Starting Up Sif Task Force</p>
                        <p className="heading-text-5 top-margin-20">Follow the steps below to get coding tasks done for you.</p>
                      </div>
                      <div className="fixed-column-190">
                        <div className="light-background slightly-rounded-corners bottom-margin padding-15 description-text-1">
                          <p className="full-width right-text">Your credits: <label className="cta-color bold-text">{this.state.userCredits}</label></p>

                        </div>

                        <div className="full-width right-text">
                          <button className="background-button" onClick={() => this.openAddCreditsWindow()}>
                            <p className="cta-color bold-text description-text-1">Add more credits</p>
                          </button>
                        </div>

                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" />

                    <div className="row-30">
                      <hr />
                    </div>

                    {(!this.state.hideWhitelist) && (
                      <div>
                        <div className="row-30">
                          <div>
                            <div className="float-left">
                              <p className="heading-text-4 bold-text">Step 1: Get Whitelisted</p>
                            </div>
                            {(this.state.isWhitelisted) && (
                              <div className="float-left left-padding-20 top-margin-3">
                                <img className="image-20-fit" alt="GC" src={checkmarkIconBlue} />
                              </div>
                            )}
                            <div className="clear" />
                          </div>

                          <div className="top-margin-20">
                            {(this.state.isWhitelisted) ? (
                              <div>
                                <p>You have been whitelisted! Proceed to step 2!</p>
                              </div>
                            ) : (
                              <div>
                                <p>Sif Task Force is not currently open to all users. In order to run the app, you need to be whitelisted. Once you have been approved, we will reach out via email to notify you of your approval.</p>
                              </div>
                            )}
                            {/*
                            <p>Sif Task Force is not currently open to all users. So, you can't register openly. You must be whitelisted. Submit the form <a href="https://forms.gle/U8fP2fctXpGnKXH98" target="_blank" rel="noopener noreferrer">here</a> to be whitelisted for Sif Task Force. The form will not ask for your name, so you can remain anonymous.</p>

                            <p className="top-margin-20">Once you have been approved, we will reach out via email to notify you of your approval. You can then proceed to step 2. </p>*/}
                          </div>
                        </div>

                        <div className="row-30">
                          <hr />
                        </div>
                      </div>
                    )}

                    {(!this.state.hidePrepareYourData) && (
                      <div>

                        <div>
                          <div className="float-left">
                            <p className="heading-text-4 bold-text">Step {(this.state.hideWhitelist) ? "1" : "2"}: Prepare Your Data</p>
                          </div>
                          {(this.state.gitHubAccessToken && this.state.repoName && this.state.issueNumber) && (
                            <div className="float-left left-padding-20 top-margin-3">
                              <img className="image-20-fit" alt="GC" src={checkmarkIconBlue} />
                            </div>
                          )}
                          <div className="clear" />
                        </div>

                        <div className="top-margin-20">
                          <p>As a user, you will need the following variables:</p>

                          <div className="top-margin-20 bold-text">
                            <div className="fixed-column-40 standard-border padding-10">
                              <p>#</p>
                            </div>
                            <div className="calc-column-offset-40">
                              <div className="relative-column-30 standard-border padding-10">
                                <p>Variable Name</p>
                              </div>
                              <div className="relative-column-70 standard-border padding-10">
                                <p>Variable Description</p>
                              </div>
                              <div className="clear" />
                            </div>
                            <div className="clear" />
                          </div>

                          {(this.state.variables) && (
                            <div>
                              {this.state.variables.map((item, index) =>
                                <div key={"sr|" + index}>
                                  <div>
                                    <div className="fixed-column-40 standard-border padding-10">
                                      <p>{index + 1}</p>
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <div className="relative-column-30 standard-border padding-10">
                                        <p>{item.name}</p>
                                      </div>
                                      <div className="relative-column-70 standard-border padding-10">
                                        <p>{item.description}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                        </div>
                      </div>
                    )}

                    {(!this.state.hideGitHubAccessToken && !this.state.preloadedAccessToken) && (
                      <div className="row-25">
                        <p className="heading-text-4 bold-text">1. Add Your GitHub Personal Access Token</p>

                        <div className="top-margin-20">
                          <p>Your token is the personal access token generated in GitHub. Follow the instructions in <a href="https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens#" target="_blank" rel="noopener noreferrer">this link</a> to generate a GitHub personal access token. Your access token only needs to include the following scope:</p>
                        </div>

                        {(this.state.tokenScopes && this.state.tokenScopes.length > 0) && (
                          <div className="padding-30 primary-background-wildly-light top-margin-30 bottom-margin-30">
                            {this.state.tokenScopes.map((value, index) =>
                              <div className="top-margin bottom-margin">
                                <div className="relative-column-33">
                                  <div className="fixed-column-25">
                                    <div className="mini-spacer" />
                                    <img src={checkmarkIconBlue} alt="STF" className="image-14-fit" />
                                  </div>
                                  <div className="calc-column-offset-25">
                                    <p className="description-text-2">{value.name} ({value.access})</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                                {(index % 3 === 2) && (
                                  <div className="clear" />
                                )}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}

                        <div className="top-margin-20">
                          <div className="container-left">
                            <label className="profile-label">GitHub Token</label>
                            <input className="text-field full-width height-40" type="password" placeholder="Your GitHub token..." name="gitHubAccessToken" value={this.state.gitHubAccessToken} onChange={this.formChangeHandler} />
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    )}

                    <div className="row-30">
                      <p className="heading-text-4 bold-text">{(this.state.preloadedAccessToken) ? "1" : "2"}. Add Your Repo Name</p>

                      <div className="top-margin-20">
                        <p>Please add the name of the repo that this ticket applies to. This includes both your GitHub username and the repo name separated by a slash. For example “creightontaylor/testing-stf”.</p>
                      </div>

                      <div className="top-margin-20">
                        <img className="image-full-auto" alt="GC" src={repoNameRetrievalSS} />
                      </div>

                      <div className="top-margin-20">
                        <div className="container-left">
                          <label className="profile-label">Repo Name</label>
                          <input className="text-field standard-border" type="text" placeholder="(e.g., creightontaylor/testing-stf)." name="repoName" value={this.state.repoName} onChange={this.formChangeHandler} />
                        </div>
                        <div className="clear" />
                      </div>
                    </div>

                    <div className="row-30">
                      <p className="heading-text-4 bold-text">{(this.state.preloadedAccessToken) ? "2" : "3"}. Add Your Issue</p>

                      {(this.state.showIssueToggle) && (
                        <div className="top-margin-20">
                          <p className="row-15">Would you like to create a new issue?</p>
                          <Switch
                            onChange={(change) => this.setState({ createNewIssue: change })}
                            checked={this.state.createNewIssue}
                            id="normal-switch"
                          />
                        </div>
                      )}

                      {(!this.state.showIssueToggle || (this.state.showIssueToggle && !this.state.createNewIssue)) && (
                        <div>
                          <div className="top-margin-20">
                            <p>Please add the number for an issue that you created in GitHub within the above repo. This is the ID of the issue. These IDs are chronological, so the first issue you create will have an ID of 1.</p>
                          </div>

                          <div className="top-margin-20">
                            <img className="image-full-auto" alt="GC" src={issueNumberRetrievalSS} />
                          </div>

                          <div className="top-margin-20">
                            <div className="container-left">
                              <label className="profile-label">Issue Number</label>
                              <input className="number-field full-width" type="number" placeholder="(e.g., 12)." name="issueNumber" value={this.state.issueNumber} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      {(this.state.showIssueToggle && this.state.createNewIssue) && (
                        <div className="top-margin-20">
                          {/*
                          <div className="row-15">
                            <p className="heading-text-5">Specify Your Project</p>
                          </div>
                            name, description, desiredOutcome, budget, timeframe
                          */}

                          <div className="row-15">
                            <label className="profile-label">Name of Ticket <label className="error-color bold-text">*</label></label>
                            <input className="text-field" type="text" placeholder="(e.g., Adding Messaging to My App)" name="name" value={this.state.name} onChange={this.formChangeHandler} />
                            <p className="profile-descriptor top-margin">A concise, descriptive title of the change request, feature request, or bug report.</p>
                          </div>

                          <div className="row-15">
                            <label className="profile-label">Description <label className="error-color bold-text">*</label></label>
                            <textarea className="text-area" name="description" placeholder={"(e.g., Create messaging functionality in the app...yadda...)"} value={this.state.description} onChange={this.formChangeHandler}/>
                            <p className="profile-descriptor top-margin">A brief overview of the issue or feature request. Include the goal or expected outcome.</p>
                          </div>

                          <div className="row-15">
                            <label className="profile-label">Background / Context (Optional)</label>
                            <textarea className="text-area" name="background" placeholder={"(e.g., This project started when..)"} value={this.state.background} onChange={this.formChangeHandler}/>
                            <p className="profile-descriptor top-margin">Any background information or context that helps explain why this ticket was created. For feature requests, explain the problem it solves or the value it adds.</p>
                          </div>

                          <div className="row-15">
                            <label className="profile-label">User Stories (Recommended)</label>
                            <textarea className="text-area" name="userStories" placeholder={"(e.g., As a user, I want to message my friends when I get a promotion..)"} value={this.state.userStories} onChange={this.formChangeHandler}/>
                            <p className="profile-descriptor top-margin">For feature requests or enhancements, define user stories to outline who will benefit and how. Consider the format: As a [role], I want [feature], so that [benefit].</p>
                          </div>

                          <div className="row-15">
                            <label className="profile-label">Acceptance Criteria (Recommended)</label>
                            <textarea className="text-area" name="acceptanceCriteria" placeholder={"(e.g., As a user, I want to message my friends when I get a promotion..)"} value={this.state.acceptanceCriteria} onChange={this.formChangeHandler}/>
                            <p className="profile-descriptor top-margin">List the specific criteria that must be met for this ticket to be considered complete. This should include functional requirements, non-functional requirements, and any specific details that need to be addressed. For behavior driven scenarios, the use of Gherkin format can help tremendously. Here’s a template: Given a specific initial context, When a specific event occurs, Then ensure a specific outcome.</p>
                          </div>

                          <div className="row-15">
                            <label className="profile-label">Expected vs. Actual Behavior & Steps to Reproduce (Recommended)</label>
                            <textarea className="text-area" name="bugBehavior" placeholder={"(e.g., As a user, I want to message my friends when I get a promotion..)"} value={this.state.bugBehavior} onChange={this.formChangeHandler}/>
                            <p className="profile-descriptor top-margin">Clearly describe what you expected to happen versus what actually happened. Include relevant details about the environment in which the bug was observed. Provide detailed steps to reproduce the issue, including: Navigate to..., Click on..., and Observe…</p>
                          </div>

                          <div className="row-15">
                            <label className="profile-label">Possible Solutions/Workarounds (Optional)</label>
                            <textarea className="text-area" name="potentialSolutions" placeholder={"(e.g., As a user, I want to message my friends when I get a promotion..)"} value={this.state.potentialSolutions} onChange={this.formChangeHandler}/>
                            <p className="profile-descriptor top-margin">Suggest any potential solutions, fixes, or workarounds that could address the issue or implement the feature.</p>
                          </div>

                          <div className="row-15">
                            <label className="profile-label">Additional Notes (Optional)</label>
                            <textarea className="text-area" name="additionalNotes" placeholder={"(e.g., As a user, I want to message my friends when I get a promotion..)"} value={this.state.additionalNotes} onChange={this.formChangeHandler}/>
                            <p className="profile-descriptor top-margin">Any other information that might be helpful, such as links to related tickets, documentation, or external resources.</p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row-30">
                      <p className="heading-text-4 bold-text">{(this.state.preloadedAccessToken) ? "3" : "4"}. Add The Credits You'd Like to Spend</p>
                      <p className="top-margin-20">Please add the number of credits that you would like to spend. You have <label className="cta-color light-background padding-3">{this.state.userCredits}</label> available credits. <button className="background-button cta-color bold-text" onClick={() => this.openAddCreditsWindow()}>Add more credits</button></p>

                      <div className="row-20 top-margin">
                        <div className="container-left">
                          <input type="number" className="number-field cta-color" placeholder="0" name="credits" value={this.state.credits} min="0" max={this.state.userCredits.toString()} onChange={this.formChangeHandler}></input>
                        </div>

                        <div className="clear" />
                      </div>

                      {(this.state.credits && this.state.credits !== '0') && (
                        <div>
                          <p>You will have <label className="cta-color bold-text">{this.state.userCredits - Number(this.state.credits)}</label> credits remaining.</p>
                        </div>
                      )}

                      <p className="top-margin-20">Note: spending more credits will increase the speed of execution on your ticket and improve the outcome.</p>

                    </div>

                    <div className="row-30">
                      <p className="heading-text-4 bold-text">{(this.state.preloadedAccessToken) ? "4" : "5"}. Add a Deadline</p>
                      <p className="top-margin-20">Please add a deadline for completing this ticket.</p>

                      <div className="row-20 top-margin">
                        <div className="container-left">
                          <input type="date" className="date-field cta-color" placeholder="0" name="deadline" value={this.state.deadline} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>


                    <div className="row-30">
                      <hr />
                    </div>

                    <div className="row-30">

                      {(this.state.errorMessage) && <p className="error-color row-10 description-text-2">{this.state.errorMessage}</p>}
                      {(this.state.successMessage) && <p className="cta-color row-10 description-text-2">{this.state.successMessage}</p>}

                      <button className={(!this.state.isReady || this.state.isSaving || this.state.taskforceIsRunning) ? "btn btn-primary medium-background standard-border" : "btn btn-primary"} onClick={() => this.startTaskforce()}>Start Sif Task Force</button>
                      {/*
                      {(this.state.gitHubToken && this.state.repoName && this.state.issueNumber) ? (
                        <div className="top-margin-40">

                          <button className={(this.state.taskforceIsRunning) ? "btn btn-quaternary" : "btn btn-primary"} onClick={() => this.startTaskforce()}>{(this.state.taskforceIsRunning) ? "Stop Sif Task Force" : "Start Sif Task Force"}</button>

                          {(this.state.taskforceIsRunning) && (
                            <div className="top-margin-20">
                              <p className="full-width bold-text">Sif Task Force has started up! Follow the conversation on GitHub <a href={this.state.gitHubLink} target="_blank" rel="noopener noreferrer">here</a>.</p>

                              <div className="top-margin-20">
                                <div className="relative-column-33 padding-20">
                                  <img src={robotGIF} alt="GC" className="image-full-auto" />
                                  <p className="heading-text-5 bold-text full-width center-text top-margin">Customer Agent</p>
                                  <p className="full-width center-text top-margin-20 description-text-1">I'm working on summarizing the issue and creating an initial workflow...</p>
                                </div>
                                <div className="relative-column-33 padding-20">
                                  <img src={callWaitingGIF} alt="GC" className="image-full-auto" />
                                  <p className="heading-text-5 bold-text full-width center-text top-margin">Worker Agent #1</p>
                                  <p className="full-width center-text top-margin-20 description-text-1">I'm waiting on the customer agent to create an initial workflow so that I can give feedback...</p>
                                </div>
                                <div className="relative-column-33 padding-20">
                                  <img src={callWaitingGIF} alt="GC" className="image-full-auto" />
                                  <p className="heading-text-5 bold-text full-width center-text top-margin">Worker Agent #2</p>
                                  <p className="full-width center-text top-margin-20 description-text-1">I'm waiting on the customer agent to create an initial workflow so that I can give feedback...</p>
                                </div>
                                <div className="clear" />

                              </div>

                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="top-margin-20">
                          <p className="error-color">Please add your GitHub token, repo name, and issue number to start Sif Task Force.</p>
                        </div>
                      )}*/}
                    </div>
                  </div>
                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               >
                {(this.state.showBuyCredits) && (
                  <div key="showBuyCredits" className="full-width padding-20">
                    <SubBuyCredits closeModal={this.closeModal} />
                  </div>
                )}

              </Modal>

              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand)}
            </div>
        )
    }
}

export default withRouter(Dashboard);
