import React, {Component} from 'react';
import LoginForm from './Subcomponents/LoginForm';
import withRouter from '../components/Functions/WithRouter';

class SignIn extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      console.log('componentDidMount called on signin')
    }

    render() {
      return (
        <div>
          <LoginForm type="SignIn" />
        </div>
      )
    }
}

export default withRouter(SignIn);
