import React from 'react';

const AppFooter = () => {
  console.log('did we get it>!!>>!>!>',)

  return (
    <div>
      {/*
      <SubHelpPrompt history={history} orgCode={orgCode} orgLogo={orgLogo}
        passedCommand={passedCommand} hideNav={hideNav} openUpAssistant={openUpAssistant}
        showPromptDesigner={showPromptDesigner} passedCommandDraft={passedCommandDraft}
        docContent={docContent} showGradePrompt={showGradePrompt} modalIsOpen={modalIsOpen}
        clickerToggler={clickerToggler}
      />*/}
    </div>
  )
}

export default AppFooter;
