import React, {Component } from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import Axios from 'axios';
import {globalVars} from '../config/globalVars';
import withRouter from '../components/Functions/WithRouter';

const addProfilePhotoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-profile-photo-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';

class EditProfile extends Component {
    constructor(props) {
      super(props)
      this.state = {
        disableEditing: true
      }

      this.renderProfilePic = this.renderProfilePic.bind(this)
      this.renderProfileBasics = this.renderProfileBasics.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');

      this.setState({ emailId: email, username, cuFirstName, cuLastName });

      if (username) {

        let baseURL = 'https://api.siftaskforce.com'
        if (globalVars.useTestAPI) {
          baseURL = 'https://testapi.siftaskforce.com'
        }

        // retrieve profile data
        Axios.get(baseURL + '/profile', { params: { username }})
        .then((response) => {
          console.log('Profile query attempted', response.data);

          if (response.data.success) {
            console.log('profile info query worked')

          } else {
            console.log('profile query did not work', response.data.message)
            //this.setState({ retrievedEmail: true })
          }
        }).catch((error) => {
            console.log('Profile query did not work for some reason', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event)

      this.setState({ repeatedPassword: event.target.value })

    }

    renderProfilePic() {
      console.log('renderProfilePic called')

      return (
        <div key="renderProfilePic" className="row-10">
          <div>
            <div className="row-10">
              <div className="upload-image">
                <div className="width-150">
                  <div className="relative-position">
                    <label for="profilePic" className="profile-pic-button">
                      <img src={
                        this.state.profilePicImage ? ( this.state.profilePicImage )
                        : this.state.pictureURL ? ( this.state.pictureURL )
                        : this.state.profilePicPath ? ( this.state.profilePicPath )
                        : ( addProfilePhotoIcon)}
                      alt="GC" for="profilePic" className={(this.state.profilePicImage || this.state.profilePicPath || this.state.pictureURL) ? "profile-image-largish" : "profile-image-largish-squared"}/>
                      {(this.state.profilePicImage || this.state.profilePicPath || this.state.pictureURL) && (
                        <div className="bottom-right-overlay">
                          <div className="bottom-right-subcontainer">
                            <img src={addIcon} alt="Compass add icon" className="image-auto-18 center-item"/>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                    </label>
                    <input type="file" id="profilePic" name="profilePic" onChange={this.formChangeHandler} accept="image/*" />
                  </div>
                </div>

                <div className="clear" />
                <div className="spacer" />
                <p className="description-text-color description-text-2">Dimensions: 150 x 150</p>

                { (this.state.serverSuccessProfilePic) ? (
                  <p className="success-message">{this.state.serverSuccessMessageProfilePic}</p>
                ) : (
                  <p className="error-message">{this.state.serverErrorMessageProfilePic}</p>
                )}
              </div>

            </div>

          </div>
        </div>
      )
    }

    renderProfileBasics() {
      console.log('renderProfileBasics called')

      return (
        <div key="renderProfileBasics">
          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">First Name</label>
              <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstNameValue} onChange={this.formChangeHandler} />
            </div>
            <div className="container-right">
              <label className="profile-label">Last Name</label>
              <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastNameValue} onChange={this.formChangeHandler}/>
            </div>
            <div className="clear" />
          </div>

          <div className="row-10">
            <label className="profile-label">Headline (70 chars max)</label>
            <input className="text-field" type="text" maxlength={70} placeholder="A sentence that describes who you are or what you're doing..." name="headline" value={this.state.headline} onChange={this.formChangeHandler} />
          </div>
        </div>
      )
    }

    handleSubmit(event) {
      console.log('handleSubmit called in EditProfileDetails')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const emailId = this.state.emailId
      let updatedAt = new Date();

      if (this.state.textFormHasChanged) {
          console.log('used has changed the text portions of the form!!!')

          if (this.state.firstNameValue === '') {
            this.setState({ errorMessage: 'Please add your first name', isSaving: false })
          } else if (this.state.lastNameValue === '') {
            this.setState({ errorMessage: 'Please add your last name', isSaving: false})
          } else {

            const rawPictureURL = this.state.rawPictureURL
            const pictureURL = this.state.pictureURL
            const firstNameValue = this.state.firstNameValue.trim()
            const lastNameValue = this.state.lastNameValue.trim()
            const headline = this.state.headline

            const userObject = {
              emailId, rawPictureURL, pictureURL, firstNameValue, lastNameValue, headline, updatedAt
            }

            Axios.post('/api/users/profile/details', userObject)
            .then((response) => {

              if (response.data.success) {
                console.log('successfully saved profile')

                localStorage.setItem('firstName', firstNameValue)
                localStorage.setItem('lastName', lastNameValue)
                localStorage.setItem('pictureURL', pictureURL)

                this.setState({ textFormHasChanged: false, isSaving: false,
                  successMessage: 'Basic profile information saved successfully!', errorMessage: null
                })

              } else {
                console.log('profile save was not successful')

                this.setState({ textFormHasChanged: false, isSaving: false,
                    errorMessage: response.data.message, successMessage: null
                })
              }
            }).catch((error) => {
                console.log('Saving the info did not work', error);
                this.setState({ textFormHasChanged: false, isSaving: false,
                  errorMessage: error.toString(), successMessage: null
                })
            });
          }

      } else {
        console.log('testing 123')
        this.setState({ errorMessage: 'No changes detected with basic info', isSaving: false })
      }
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} />

            <div>
              <div className="standard-container">
                {(this.state.disableEditing) ? (
                  <div>
                    <div>
                      <p className="heading-text-2">Profile Information</p>
                    </div>

                    <div className="row-40">
                      <p>No profile information is available</p>
                    </div>

                  </div>
                ) : (
                  <div>
                    <div>
                      <p className="heading-text-2">Edit Profile Info</p>
                    </div>

                    <form>
                      {this.renderProfilePic()}
                      {this.renderProfileBasics()}

                      <div className="spacer" /><div className="spacer" />

                      {(this.state.errorMessage) && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}
                      {(this.state.serverErrorMessageProfilePic) && <p className="error-color description-text-2 row-5">{this.state.serverErrorMessageProfilePic}</p>}
                      {(this.state.serverSuccessMessageProfilePic) && <p className="cta-color description-text-2 row-5">{this.state.serverSuccessMessageProfilePic}</p>}

                      <div className="row-10">
                        <button onClick={this.handleSubmit} type="button" className="btn btn-primary">Save Profile Changes</button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>

            {AppFooter()}
          </div>
      )
    }
}

export default withRouter(EditProfile);
