import React, {Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';

import withRouter from '../components/Functions/WithRouter';

const storyIconBig = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/input-icon-big.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const notificationsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/notifications-icon-blue.png';

class Notifications extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.renderNotifications = this.renderNotifications.bind(this);
      this.clearUnreadNotifications = this.clearUnreadNotifications.bind(this);
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');

      this.setState({ emailId: email, username });

    }

    renderNotifications() {
      console.log('renderNotifications called')

      let rows = [];

      for (let i = 1; i <= this.state.notifications.length; i++) {
        console.log(i);

        const index = i - 1

        let message = ''
        let notiPath = '/app/logs'

        let objectId = ''
        let additionalItem = ''

        let notiDate = ''
        if (this.state.notifications[i - 1].createdAt) {
          notiDate = this.state.notifications[i - 1].createdAt.substring(0,10)
        }

        let mainClass = "calc-column-offset-170"
        let arrowClass = "fixed-column-30"
        if (this.state.onMobile) {
          mainClass = "calc-column-offset-65"
          arrowClass = "fixed-column-22"
        }

        let senderName = this.state.notifications[i - 1].senderFirstName + ' ' + this.state.notifications[i - 1].senderLastName
        if (!this.state.notifications[i - 1].senderFirstName) {
          senderName = 'Error Getting Name'
        }

        if (this.state.notifications[i - 1].type === 'Advisor Request') {

          if ( this.state.notifications[i - 1].isDecided === true ) {

            if (this.state.notifications[i - 1].isApproved === true ) {
              message = 'career advisor request was approved'
            } else {
              message = 'career advisor request was denied'
            }
          } else {
            message = 'requests to be your career advisor'
          }
        } else {

          message = this.state.notifications[i - 1].message

          if (this.state.notifications[index].type === 'Track Approval') {
            objectId = this.state.notifications[i - 1].objectId
            notiPath = '/app/opportunities/' + objectId
          }
        }

        rows.push(
          <Link className="secret-link" to={notiPath} state={{ objectId, passedLogType: additionalItem }}>
            <div key={i} className={this.state.notifications[index].isRead ? "table-cell-1" : "table-cell-1 faint-highlight"} >
              <div className="spacer" />
              <div>
                <div className="fixed-column-40">
                  <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <img src={(this.state.notifications[index].senderPictureURL) ? this.state.notifications[index].senderPictureURL : notificationsIconBlue} alt="GC" className="image-auto-40"/>
                </div>

                <div className={mainClass + ' all-padding-10'}>
                  <label className="heading-text-5">{senderName}</label>
                  <div className="clear" />
                  <div className="half-spacer" />
                  <label className="description-text-color">{message}</label>
                </div>

                {(!this.state.onMobile) && (
                  <span className="fixed-column-100 right-text right-padding">
                  <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <label className="description-text-2 description-text-color right-text">{notiDate}</label>
                  </span>
                )}
                <span className={arrowClass}>
                    <div className="spacer" /><div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-16" />
                </span>

              </div>
              <div className="clear" />
              {(this.state.onMobile) && (
                <span className="left-margin-50">
                  <label className="description-text-2 description-text-color right-text">>{notiDate}</label>
                </span>
              )}
              <div className="spacer" />

              <div className="spacer" />
            </div>
          </Link>
        )
      }

      return rows;

    }

    clearUnreadNotifications(notifications) {
      console.log('clearUnreadNotifications called')
      let unreadNotificationIds = [];

      for (let i = 1; i <= notifications.length; i++) {
        console.log(i);

        if (notifications[i - 1].isRead === false) {
          unreadNotificationIds.push(notifications[i - 1]._id)
        }
      }

      for (let i = 1; i <= unreadNotificationIds.length; i++) {
        console.log(i);

        Axios.put('/api/notifications/read', {
          notificationIds: unreadNotificationIds })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Notification update worked', response.data);

            localStorage.setItem('unreadNotificationsCount', 0)

          } else {
            console.log('there was an error updating the notifications', response.data.message);
          }
        }).catch((error) => {
            console.log('Notification update did not work', error);
        });
      }
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} />

            <div>
              <div className="standard-container">
                <div>
                  <p className="heading-text-2">Notifications</p>
                  <div className="spacer" />
                </div>
                <div className="toggle-content">
                  {(this.state.notifications && this.state.notifications.length > 0) ? (
                    <div>
                      {this.renderNotifications()}
                    </div>
                  ) : (
                    <div className="toggle-empty-state-container">
                      <img src={storyIconBig} alt="Compass large endorsements icon" className="empty-state-icon"/>
                      <h3 className="empty-state-title"><strong>No Notifications</strong></h3>
                      <p className="empty-state-text">You do not have any notifications.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter()}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(Notifications);
