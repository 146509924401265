import React, {Component} from 'react';

import Axios from 'axios';
import {globalVars} from '../../config/globalVars';

const linkIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class BuyCredits extends Component {
    constructor(props) {
      super(props)
      this.state = {
        requireCreditRequest: true,
        userCredits: 100,
        purchaseAmountOptions: []
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.purchaseCredits = this.purchaseCredits.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      let username = localStorage.getItem('username');
      let userCredits = localStorage.getItem('userCredits');
      if (!userCredits) {
        userCredits = this.state.userCredits
      }
      const projectedCreditBalance = userCredits
      const purchaseAmountOptions = [
        { credits: null, price: null, description: null },
        { credits: 1, price: '$10', description: '1 for $10'},
        { credits: 5, price: '$50', description: '5 for $50'},
        { credits: 10, price: '$100', description: '10 for $100'},
        { credits: 50, price: '$500', description: '50 for $500'},
        { credits: 100, price: '$1,000', description: '100 for $1,000'},
        { credits: 500, price: '$5,000', description: '500 for $5,000'},
        { credits: 1000, price: '$10,000', description: '1,000 for $10,000'},
      ]

      this.setState({ userCredits, username, projectedCreditBalance, purchaseAmountOptions })

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ')
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (this.state.userCredits) {
        let projectedCreditBalance = this.state.userCredits

        for (let i = 1; i <= this.state.purchaseAmountOptions.length; i++) {
          if (event.target.value && this.state.purchaseAmountOptions[i - 1].description === event.target.value) {
            projectedCreditBalance = Number(projectedCreditBalance) + this.state.purchaseAmountOptions[i - 1].credits
          } else if (this.state.requireCreditRequest && event.target.value && this.state.purchaseAmountOptions[i - 1].credits === Number(event.target.value)) {
            projectedCreditBalance = Number(projectedCreditBalance) + this.state.purchaseAmountOptions[i - 1].credits
          }
        }
        this.setState({ [event.target.name]: event.target.value, projectedCreditBalance })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    purchaseCredits() {
      console.log('purchaseCredits called')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      if (!this.state.purchaseAmount) {
        this.setState({ isSaving: false, errorMessage: "Please select a purchase amount" })
      } else {

        let credits = null
        let price = null
        const userCredits = this.state.userCredits
        for (let i = 1; i <= this.state.purchaseAmountOptions.length; i++) {
          if (this.state.purchaseAmount && this.state.purchaseAmountOptions[i - 1].description === this.state.purchaseAmount) {
            credits = this.state.purchaseAmountOptions[i - 1].credits
            price = this.state.purchaseAmountOptions[i - 1].price
          }
        }

        const username = this.state.username


        const createdAt = new Date()
        const updatedAt = new Date()

        const creditObject = { credits, price, username, createdAt, updatedAt}

        let baseURL = 'https://api.siftaskforce.com'
        if (globalVars.useTestAPI) {
          baseURL = 'https://testapi.siftaskforce.com'
        }

        const postURL = baseURL + "/credits"

        Axios.post(postURL, creditObject)
        .then((response) => {

          if (response.data.success) {
            console.log('successfully saved profile')

            this.setState({ isSaving: false, successMessage: 'Successfully purchased credits!' })

          } else {
            console.log('profile save was not successful')

            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Saving the info did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    render() {

      return (
        <div>
          <div className="bottom-padding">
            <div className="calc-column-offset-30">
              <p className="heading-text-2">Buy Credits</p>
            </div>
            <div className="fixed-column-30 top-padding-5">
              <button className="background-button" onClick={() => this.props.closeModal()}>
                <img src={closeIcon} alt="GC" className="image-auto-20" />
              </button>
            </div>

            <div className="clear" />
          </div>

          <div className="spacer" />

          {(this.state.requireCreditRequest) ? (
            <div>
              <div className="row-20">
                <p className="description-text-2 bold-text bottom-padding-5">Your credit balance:</p>
                <p className="description-text-color">{this.state.userCredits}</p>
              </div>

              <div className="row-20">
                <p className="description-text-2 bold-text bottom-padding-5">Select the amount to buy:</p>
                <select name="purchaseAmount" value={this.state.purchaseAmount} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.purchaseAmountOptions.map(value =>
                    <option key={value.credits} value={value.credits}>{value.credits}</option>
                  )}
                </select>
              </div>

              {(this.state.amountToCharge) && (
                <div className="row-20">
                  <p className="description-text-2 bold-text bottom-padding-5">Your account will be charged:</p>
                  <p>{this.state.amountToCharge}</p>
                </div>
              )}

              <div className="row-20">
                <p className="description-text-2 bold-text bottom-padding-5">Your new credit balance will be:</p>
                <p>{this.state.projectedCreditBalance}</p>
              </div>

              {(this.state.successMessage) && <p className="row-5 cta-color description-text-2">{this.state.successMessage}</p>}
              {(this.state.errorMessage) && <p className="row-5 error-color description-text-2">{this.state.errorMessage}</p>}

              <div className="top-padding-30 display-inline">
                <div className="float-left right-padding-20">
                  <button className={(this.state.purchaseAmount) ? "btn btn-primary" : "btn btn-primary medium-background standard-color standard-border no-pointers"} disabled={(this.state.purchaseAmount) ? false : true} onClick={() => this.purchaseCredits()}>
                    <p>Submit Request</p>
                  </button>
                </div>
                <div className="float-left top-padding">
                  <button className="background-button" onClick={() => this.props.closeModal()}>
                    <p>Cancel</p>
                  </button>
                </div>

                <div className="clear" />
              </div>
            </div>
          ) : (
            <div>
              <div className="row-20">
                <p className="description-text-2 bold-text bottom-padding-5">Your credit balance:</p>
                <p className="description-text-color">{this.state.userCredits}</p>
              </div>

              <div className="row-20">
                <p className="description-text-2 bold-text bottom-padding-5">Select the amount to buy:</p>
                <select name="purchaseAmount" value={this.state.purchaseAmount} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.purchaseAmountOptions.map(value =>
                    <option key={value.description} value={value.description}>{value.description}</option>
                  )}
                </select>
              </div>

              {(this.state.amountToCharge) && (
                <div className="row-20">
                  <p className="description-text-2 bold-text bottom-padding-5">Your account will be charged:</p>
                  <p>{this.state.amountToCharge}</p>
                </div>
              )}

              <div className="row-20">
                <p className="description-text-2 bold-text bottom-padding-5">Your new credit balance will be:</p>
                <p>{this.state.projectedCreditBalance}</p>
              </div>

              <div className="row-20">
                <p className="description-text-2 bold-text bottom-padding-5">Promo code:</p>
                <div>
                  <div className="calc-column-offset-90 right-padding">
                    <input className="text-field" type="text" placeholder="Your promo code..." name="promoCode" value={this.state.promoCode} onChange={this.formChangeHandler} />
                  </div>
                  <div className="fixed-column-90">
                    <button className={(this.state.promoCode) ? "btn btn-squarish" : "btn btn-squarish medium-background standard-color standard-border no-pointers"} disabled={(this.state.promoCode) ? false : true} onClick={() => this.sendInvite()}>Redeem</button>
                  </div>
                  <div className="clear" />
                </div>
              </div>

              {(this.state.successMessage) && <p className="row-5 cta-color description-text-2">{this.state.successMessage}</p>}
              {(this.state.errorMessage) && <p className="row-5 error-color description-text-2">{this.state.errorMessage}</p>}

              <div className="top-padding-30 display-inline">
                <div className="float-left right-padding-20">
                  <button className={(this.state.purchaseAmount) ? "btn btn-primary" : "btn btn-primary medium-background standard-color standard-border no-pointers"} disabled={(this.state.purchaseAmount) ? false : true} onClick={() => this.purchaseCredits()}>
                    <p>Buy</p>
                  </button>
                </div>
                <div className="float-left top-padding">
                  <button className="background-button" onClick={() => this.props.closeModal()}>
                    <p>Cancel</p>
                  </button>
                </div>

                <div className="clear" />
              </div>
            </div>
          )}
        </div>
      )
    }
}

export default BuyCredits;
