import React, { Component} from 'react';
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import Axios from 'axios';
// import Modal from 'react-modal';
import {globalVars} from '../../config/globalVars';
import withRouter from '../Functions/WithRouter';
import {signUp, signIn} from '../Services/AuthRoutes';

const logoImgWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/siftaskforce/stf-logo-white.png';
// const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const gitHubLogin = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/siftaskforce/login_with_github_button.png';

class LogInWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showSSO: true,
          skipWalkthrough: false,
          mfaEnabled: false,
          hideNames: true,
          client_id: 'Iv23liBM7PnXIsDcvDzD',

          toggleLink: '/',

          isWaiting: false,
          error: {
              message: ''
          }
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.signUpUser = this.signUpUser.bind(this)
        this.signInUser = this.signInUser.bind(this)

        this.submitCode = this.submitCode.bind(this)
        this.resendCode = this.resendCode.bind(this)

    }

    componentDidMount() {
        console.log('login form page is mounting')

        let client_id = this.state.client_id
        if (globalVars.useTestAPI) {
          client_id = 'Iv23lihZmSyc9bkbXXwX'
        }

        this.setState({ client_id })

        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within login form ', this.props, prevProps)

      if (this.props.signInPage !== prevProps.signInPage) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData in LogInWidget', this.props)

      let verifyCode = localStorage.getItem('verifyCode');
      if (verifyCode && verifyCode === 'true') {
        let email = localStorage.getItem('email');
        let firstName = localStorage.getItem('firstName');
        let lastName = localStorage.getItem('lastName');
        let username = localStorage.getItem('username');

        const showVerifyCode = true
        
        this.setState({ email, firstName, lastName, username, showVerifyCode })
      }

      // let signInPage = this.props.signInPage

      const path = window.location.pathname

      let toggleLink = ''
      if (path) {
        if (path.includes("join") || path.includes("signup")) {
          toggleLink = path.replace("join","signin").replace("signup","signin")
        } else if (path.includes("signin")){
          toggleLink = path.replace("signin","signup")
        }
      }

      let mobileView = false
      if (window.innerWidth <= 768) {
        mobileView = true
      }

      this.setState({ path, toggleLink, mobileView })

    }

    formChangeHandler(event) {
      //validate strings
      if (event.target.name === 'firstName') {
        let capitalizedFirstName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        this.setState({ firstName: capitalizedFirstName })
      } else if (event.target.name === 'lastName') {
        let capitalizedLastName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        this.setState({ lastName: capitalizedLastName})
      } else if (event.target.name === 'email') {
        this.setState({ email: event.target.value })
      } else if (event.target.name === 'password') {
        this.setState({ password: event.target.value })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    async signUpUser() {
      console.log('async signUpUser called')

      this.setState({ isSaving: true, isWaiting: true, errorMessage: null,
        error: { message: null }, successMessage: null
      })

      let firstName = ''
      let lastName = ''
      if (!this.state.hideNames) {
        firstName = ReactDOM.findDOMNode(this.refs.firstName).value
        lastName = ReactDOM.findDOMNode(this.refs.lastName).value
      }
      let email = ReactDOM.findDOMNode(this.refs.email).value
      const password = ReactDOM.findDOMNode(this.refs.password).value

      const mfaEnabled = this.state.mfaEnabled

      let requestAccess = false

      // let createdAt = new Date();
      // let updatedAt = new Date();

      const returnedValue = await signUp(firstName, lastName, email, password, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, mfaEnabled, null, null, null, null, null, null, null, null, null, null, null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,requestAccess,null)

      if (returnedValue && returnedValue.success) {
        console.log('sign up was successful: ', returnedValue)

        if (this.state.mfaEnabled) {
          localStorage.setItem('verifyCode', 'true')
        }

        const skipWalkthrough = returnedValue.skipWalkthrough
        this.setState({ skipWalkthrough })

        if (this.props.modalIsOpen) {
          this.props.passData({ success: true, message: returnedValue.message })
        } else {
          if (this.state.mfaEnabled) {
            this.setState({ showVerifyCode: true, isSaving: false, isWaiting: false })
          } else {

            this.props.navigate('/app')
          }
        }

      } else if (returnedValue && returnedValue.message) {
        console.log('sign up error: ', returnedValue)
        this.setState({ isSaving: false, isWaiting: false, errorMessage: returnedValue.message,
          error: { message: returnedValue.message }
        })
      } else {

        this.setState({ isSaving: false, isWaiting: false, errorMessage: returnedValue.error.message,
          error: { message: returnedValue.error.message }
        })
      }
    }

    async signInUser() {
      console.log('async signInUser called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const email = ReactDOM.findDOMNode(this.refs.email).value
      const password = ReactDOM.findDOMNode(this.refs.password).value

      const returnedValue = await signIn(email, password, null, null)
      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)

        if (this.state.mfaEnabled) {

          const emailId = this.state.email
          const verificationCode = Math.floor(Math.random() * 100000)

          const postBody = { emailId, verificationCode }

          Axios.post('/api/send-verification-code', postBody)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('New verification code sent', response.data);
              this.setState({ showVerifyCode: true })

            } else {
              console.error('there was an error sending the verification code ', response.data.message);
              this.setState({ errorMessage: response.data.message })

            }
          }).catch((error) => {
              console.log('The new verification code send did not work', error);
              this.setState({ errorMessage: error.toString() })
          });
        } else {

          if (this.props.modalIsOpen) {
            this.props.passData({ success: true, message: returnedValue.message })
          } else {

            this.props.navigate('/app')
          }
        }

      } else if (returnedValue && returnedValue.message) {
        console.log('show returnedValue 3: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.message, error: { message: returnedValue.message }})
      } else if (returnedValue && returnedValue.error) {
        console.log('show returnedValue 4: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.error.message, error: { message: returnedValue.error.message }})
      } else {
        console.log('show returnedValue 5: ', returnedValue)
        // this.setState({ isSaving: false, errorMessage: returnedValue.message })
        this.setState({ isSaving: false, errorMessage: 'an unknown error has occurred', error: { message: 'an unknown error has occurred' }})
      }
    }

    submitCode() {
      console.log('submitCode called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const emailId = this.state.email
      const verificationCode = this.state.verificationCode

      const postBody = { emailId, verificationCode }

    }

    resendCode() {
      console.log('resendCode called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const emailId = this.state.email
      const verificationCode = Math.floor(Math.random() * 100000)

      const postBody = { emailId, verificationCode }

      Axios.post('/api/send-verification-code', postBody)
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('New verification code sent', response.data);
          this.setState({ successMessage: response.data.message })

        } else {
          console.error('there was an error sending the verification code ', response.data.message);
          this.setState({ errorMessage: response.data.message })

        }
      }).catch((error) => {
          console.log('The new verification code send did not work', error);
          this.setState({ errorMessage: error.toString() })

      });
    }

    render() {

      return (
        <div>
          <header className={(this.props.modalIsOpen) ? "" : "full-page-background-image"}>
            {(this.state.showSSO) ? (
              <div>

                <div>
                  {(this.state.mobileView) ? (
                    <div>
                      <div className="relative-column-20">
                        <div className="width-50 height-40" />
                      </div>
                      <div className="relative-column-60">
                        <div className="flex-container row-direction flex-center">
                          <Link to={'/'} className="clear-border"><img src={logoImgWhite} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                        </div>
                      </div>
                      <div className="relative-column-20">
                      </div>
                      <div className="clear" />

                    </div>
                  ) : (
                    <div className="row full-width">
                      <div className="relative-column-20">
                        <div className="width-50 height-40" />
                      </div>
                      <div className="relative-column-60">
                        <div className="flex-container row-direction flex-center">
                          <Link to={'/'} className="clear-border"><img src={logoImgWhite} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                        </div>
                      </div>
                      <div className="relative-column-20">
                      </div>

                      <div className="clear" />
                    </div>
                  )}
                </div>

                <div className={"card top-margin-7-percent width-70-percent center-horizontally rounded-corners"}>
                  <p className="heading-text-2 center-text top-margin-20">Join Sif Task Force</p>

                  <p className="center-text top-margin-30">Log in with GitHub to instantly sync your projects and tasks with your development environment.</p>

                  <div className="full-width center-horizontally row-30 top-margin-40 center-text">
                    <a className="background-button" href={'https://github.com/login/oauth/authorize?client_id=' + this.state.client_id}>
                      <img src={gitHubLogin} alt="GC" className="image-auto-50" />
                    </a>
                  </div>

                  <p className="center-text row-40">This single sign-on solution saves you time, reduces the hassle of remembering multiple passwords, and streamlines your workflow by integrating directly with your GitHub repositories.</p>

                </div>
              </div>
            ) : (
              <div>
                {(this.state.showVerifyCode) ? (
                  <div>
                    {(window.innerWidth <= 768) ? (
                      <div>
                        <div className="relative-column-20">
                          <div className="width-50 height-40" />
                        </div>
                        <div className="relative-column-60">
                          <div className="flex-container row-direction flex-center">
                            <Link to={'/'} className="clear-border"><img src={logoImgWhite} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                          </div>
                        </div>
                        <div className="relative-column-20">
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="row full-width">
                        <div className="relative-column-20">
                          <div className="width-50 height-40" />
                        </div>
                        <div className="relative-column-60">
                          <div className="flex-container row-direction flex-center">
                            <Link to={'/'} className="clear-border"><img src={logoImgWhite} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                          </div>
                        </div>
                        <div className="relative-column-20">
                        </div>

                        <div className="clear" />
                      </div>
                    )}

                    <div className="login-container standard-border" style={{ height: 'auto', paddingBottom: '50px' }}>
                      <h2 className="login-title normal-weight">Verify Your Identity</h2>

                      <div className="padding-40">
                        <div className="row-10">
                          <p>A verification code has been sent to your email. Enter the code to continue.</p>
                        </div>

                        <div className="row-10">
                          <input className="number-field" type="number" placeholder="e.g. 123..." name="verificationCode" value={this.state.verificationCode} onChange={this.formChangeHandler}/>
                        </div>

                        <div className="row-15">
                          <button className={(this.state.verificationCode) ? "btn btn-squarish" : "btn btn-squarish wash-out-2"} disabled={(this.state.verificationCode) ? false : true} onClick={() => this.submitCode()}>Continue</button>
                        </div>

                        <div className="row-5">
                          <button className="background-button cta-color bold-text" onClick={() => this.resendCode()}>Resend code ></button>
                        </div>

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                        <div className="top-padding-20">
                          <p>Need help? <Link to="/contact" target="_blank">Contact us</Link></p>
                        </div>
                      </div>

                    </div>
                  </div>
                ) : (
                  <div>
                    {((!this.props.modalIsOpen && this.props.type && this.props.type === 'SignUp') || (this.props.modalIsOpen && !this.state.signInPage)) ? (
                      <div>
                        {(!this.props.modalIsOpen) && (
                          <div>
                            {(this.state.mobileView) ? (
                              <div>
                                <div className="relative-column-20">
                                  <div className="width-50 height-40" />
                                </div>
                                <div className="relative-column-60">
                                  <div className="flex-container row-direction flex-center">
                                    <Link to={'/'} className="clear-border"><img src={logoImgWhite} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                  </div>
                                </div>
                                <div className="relative-column-20">
                                </div>
                                <div className="clear" />

                              </div>
                            ) : (
                              <div className="row full-width">
                                <div className="relative-column-20">
                                  <div className="width-50 height-40" />
                                </div>
                                <div className="relative-column-60">
                                  <div className="flex-container row-direction flex-center">
                                    <Link to={'/'} className="clear-border"><img src={logoImgWhite} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                  </div>
                                </div>
                                <div className="relative-column-20">
                                </div>

                                <div className="clear" />
                              </div>
                            )}
                          </div>
                        )}

                        <div className={(this.props.modalIsOpen) ? "" : "login-container standard-border horizontal-padding-6"} style={(this.props.modalIsOpen) ? {} : { height: 'auto', paddingBottom: '50px' }}>
                          <h2 className="login-title normal-weight">Join Sif Task Force</h2>

                          {(this.state.successMessage) ? (
                            <div className="horizontal-padding-3">
                              <p className="success-message">{this.state.successMessage}</p>
                              {(this.state.successLink) && (
                                <div className="top-padding-20">
                                  <a href={this.state.successLink} target="_blank" rel="noopener noreferrer">{this.state.successLink}</a>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div className="center-text center-horizontally top-padding width-80-percent">
                                {(this.state.hideNames) ? (
                                  <div>
                                    <div className="row-15">
                                      <div className="unselected-border">
                                        <input className="text-field clear-border height-40" type="email" name="email"
                                          ref="email" placeholder="email" value={this.state.email}
                                          onChange={this.formChangeHandler}
                                        />
                                      </div>
                                    </div>
                                    <div className="row-15">
                                      <div className="unselected-border">
                                        <input className="text-field clear-border height-40" type="Password" name="password"
                                          ref="password" placeholder="password" value={this.state.password}
                                          onChange={this.formChangeHandler}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="row-15">
                                      <div className="container-left">
                                        <div className="unselected-border">
                                          <input className="text-field clear-border height-40" type="text" name="firstName"
                                          ref="firstName" placeholder="First Name" value={this.state.firstName}
                                          onChange={this.formChangeHandler}
                                          />
                                        </div>
                                      </div>
                                      <div className="container-right">
                                        <div className="unselected-border">
                                          <input className="text-field clear-border height-40" type="text" name="lastName"
                                            ref="lastName" placeholder="Last Name" value={this.state.lastName}
                                            onChange={this.formChangeHandler}
                                          />
                                        </div>
                                      </div>
                                      <div className="clear" />
                                    </div>

                                    <div className="row-15">
                                      <div className="container-left">
                                        <div className="unselected-border">
                                          <input className="text-field clear-border height-40" type="email" name="email"
                                            ref="email" placeholder="Email" value={this.state.email}
                                            onChange={this.formChangeHandler}
                                          />
                                        </div>
                                      </div>
                                      <div className="container-right">
                                        <div className="unselected-border">
                                          <input className="text-field clear-border height-40" type="Password" name="password"
                                            ref="password" placeholder="Password" value={this.state.password}
                                            onChange={this.formChangeHandler}
                                          />
                                        </div>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="login-other-elements">
                                  <button
                                      className="btn btn-primary login-button"
                                      disabled={this.state.isSaving}
                                      onClick={() => this.signUpUser()}
                                      >
                                      Sign Up</button>
                                  <p className="login-error-message">{this.state.error.message}</p>

                                  <p className="login-switch">Already have an account? {(this.props.modalIsOpen) ? <button className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: true })}>Sign in instead</button> : <Link to={this.state.toggleLink}>Sign in instead</Link>}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {(!this.props.modalIsOpen) && (
                          <div>
                            {(this.state.mobileView) ? (
                              <div className="row full-width">
                                <div className="relative-column-20">
                                  <div className="width-50 height-40" />
                                </div>
                                <div className="relative-column-60">
                                  <div className="flex-container row-direction flex-center">
                                    <Link to={'/'} className="clear-border"><img src={logoImgWhite} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                  </div>
                                </div>
                                <div className="relative-column-20">
                                </div>

                                <div className="clear" />
                              </div>
                            ) : (
                              <div className="row full-width">
                                <div className="relative-column-20">
                                  <div className="width-50 height-40" />
                                </div>
                                <div className="relative-column-60">
                                  <div className="flex-container row-direction flex-center">
                                    <Link to={'/'} className="clear-border"><img src={logoImgWhite} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                  </div>
                                </div>
                                <div className="relative-column-20">
                                </div>

                                <div className="clear" />
                              </div>
                            )}
                          </div>
                        )}

                        <div className={(this.props.modalIsOpen) ? "" : "login-container horizontal-padding-6"}>
                          <h2 className="login-title normal-weight">Sign In</h2>

                          <div className="row-15 center-horizontally width-80-percent">
                            <div className="row-15">
                              <div className="unselected-border">
                                <input
                                  className="text-field clear-border height-40"
                                  type="email"
                                  ref="email"
                                  placeholder="email"
                                  onChange={event => this.setState({email: event.target.value})}
                                  onKeyPress={(e) => (e.key === 'Enter') ? this.signInUser() : console.log('key clicked')}
                                />
                              </div>
                            </div>
                            <div className="row-15">
                              <div className="unselected-border">
                                <input
                                  className="text-field clear-border height-40"
                                  type="password"
                                  ref="password"
                                  placeholder="password"
                                  onChange={event => this.setState({password: event.target.value})}
                                  onKeyPress={(e) => (e.key === 'Enter') ? this.signInUser() : console.log('key clicked')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="login-other-elements">
                              <button
                                  className="btn btn-primary login-button"
                                  onClick={() => this.signInUser()}
                                  >Sign In</button>
                              <p className="login-error-message">{this.state.error.message}</p>
                              <p className="login-switch">Haven't created an account? {(this.props.modalIsOpen) ? <button className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: false })}>Sign up instead</button> : <Link to={this.state.toggleLink}>Sign up instead</Link>} </p>

                              <p className="login-switch">Forgot your password? <Link to={'/reset-password'} target="_blank">Reset password</Link></p>

                              <div className="spacer"/><div className="spacer"/>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </header>
        </div>

      )
    }
}

export default withRouter(LogInWidget);
