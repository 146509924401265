import React, {Component} from 'react';

import Axios from 'axios';

const linkIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class InviteMembers extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.copyLink = this.copyLink.bind(this)
      this.sendInvite = this.sendInvite.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ')
    }

    retrieveData() {
      console.log('retrieveData called within InviteMembers')

      let emailId = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      let inviteLink = window.location.protocol + "//" + window.location.host + "/join"

      this.setState({ emailId, username, cuFirstName, cuLastName, inviteLink })

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }

    copyLink() {
      console.log('copyLink called')

      navigator.clipboard.writeText(this.state.inviteLink)
      this.setState({ errorMessage: null, successMessage: 'Link copied!'})

    }

    sendInvite() {
      console.log('sendInvite called')

      this.setState({ errorMessage: null, successMessage: null })

      if (this.state.recipientEmail && this.state.recipient !== '') {
        if (!this.state.recipientEmail.includes('@')) {
          this.setState({ errorMessage: "Please add a valid email" })
        } else {
          console.log('send the invite')

          const senderFirstName = this.state.cuFirstName
          const senderLastName = this.state.cuLastName
          const senderEmail = this.state.emailId
          const recipientEmails = [this.state.recipientEmail]
          const recipientType = 'Student'
          const joined = false
          const active = false
          const inviteLink = this.state.inviteLink

          Axios.post('/api/members/invite', {
            senderFirstName, senderLastName, senderEmail, recipientEmails, recipientType, joined, active,
            inviteLink })
          .then((response) => {

            if (response.data.success) {
              const successMessage = this.state.recipientEmail + ' has been invited to join the Sif Task Force. They will then be able to connect with you and participate in opportunities.'

              //clear values
              this.setState({ successMessage, recipientEmail: '' })
            } else {

              this.setState({ errorMessage: response.data.message })

            }
          }).catch((error) => {
              console.log('Invite did not work', error);
              this.setState({ errorMessage: 'There was an error inviting the members' })
          });
        }
      }
    }

    render() {

      return (
        <div>
          <div className="bottom-padding">
            <div className="calc-column-offset-30">
              <p className="heading-text-2">Invite People to Sif Task Force</p>
            </div>
            <div className="fixed-column-30 top-padding-5">
              <button className="background-button" onClick={() => this.props.closeModal()}>
                <img src={closeIcon} alt="GC" className="image-auto-20" />
              </button>
            </div>

            <div className="clear" />
          </div>

          <div className="spacer" /><div className="spacer" />

          <p className="description-text-2 bold-text bottom-padding-5">To:</p>
          <textarea className="text-field" type="text" placeholder="name@gmail.com" name="recipientEmail" value={this.state.recipientEmail} onChange={this.formChangeHandler} />

          <div className="spacer" />

          {(this.state.successMessage) && <p className="row-5 cta-color description-text-2">{this.state.successMessage}</p>}
          {(this.state.errorMessage) && <p className="row-5 error-color description-text-2">{this.state.errorMessage}</p>}

          <div className="top-padding-30">
            <div className="container-left-static top-padding">
              <button className="background-button" onClick={() => this.copyLink()}>
                <div className="fixed-column-30">
                  <img src={linkIconBlue} alt="GC" className="image-auto-20" />
                </div>
                <div className="calc-column-offset-30">
                  <p>Copy invite link</p>
                </div>
                <div className="clear" />
              </button>
            </div>
            <div className="container-right-static">
              <button className={(this.state.recipientEmail && this.state.recipientEmail !== '') ? "btn btn-squarish float-right" : "btn btn-squarish float-right medium-background standard-color standard-border no-pointers"} onClick={() => this.sendInvite()}>
                <p>Send</p>
              </button>
              <div className="clear" />
            </div>
            <div className="clear" />
          </div>
        </div>
      )
    }
}

export default InviteMembers;
