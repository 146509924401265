import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
// import { HashRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import { BrowserRouter, HashRouter, Route, Navigate, Routes } from 'react-router-dom';

import FrontPage from './landingPages/FrontPage';
import AboutPage from './landingPages/AboutPage';
import ContactPage from './landingPages/ContactPage';
import ResetPasswordPage from './landingPages/ResetPasswordPage';

import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import GitHubCallback from './components/GitHubCallback';

import Dashboard from './components/Dashboard';
import EditProfile from './components/EditProfile';
import Notifications from './components/Notifications';
import Settings from './components/Settings';
import ChangePassword from './components/ChangePassword';

import './css/style.css';
import './css/queries.css';
import './vendors/css/normalize.css';
import './vendors/css/grid.css';

import {globalVars} from './config/globalVars';
let Router = BrowserRouter
if (globalVars.useHashInProduction && window.location.hostname !== 'localhost') {
  Router = HashRouter
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<FrontPage/>} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/reset-password" element={<ResetPasswordPage/>} />

        <Route path="/signup" element={<SignUp/>} />
        <Route path="/join" element={<SignUp/>} />
        <Route path="/signin" element={<SignIn/>} />
        <Route path="/github/callback/:user_token" element={<GitHubCallback/>} />

        <Route path="/app/dashboard" element={<Dashboard/>} />
        <Route path="/app/edit-profile" element={<EditProfile/>} />
        <Route path="/app/notifications" element={<Notifications/>} />
        <Route path="/app/settings" element={<Settings/>} />
        <Route path="/app/change-password" element={<ChangePassword/>} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// steps to update website:
// 1: git add .
// 2: git commit -m 'my commit message'
// 3: git push origin master
// 4: npm run deploy
// 5: ensure file path is correct (e.g., ""/stf-static-pages/favicon.ico" replaced by ""/favicon.ico"):
// 5a: 5 instances in index.html in href or src variables

// to do
// {
//   "repo_full_name": "creightontaylor/simple_website",
//   "repo_issue_id": 18,
//   "budget": 0,
//   "repo_branch": "string",
//   "github_token": "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",
//   "customer": {
//     "name": "customer_agent",
//     "reevaluation_duration": 60,
//     "reevaluation_message": 2,
//     "discussion_phase_time": 90,
//     "loop_wait": 10
//   },
//   "workers": [
//     {
//       "name": "Architect",
//       "prompt": "You are senior software architect.",
//       "minimum_rating": 8,
//       "loop_wait": 10
//     }
//   ]
// }
